app.component("patientFileTable", {
    templateUrl: "app/src/js/components/patientFileUploader/patientFileTable.html",
    controller: "patientFileTableController",
    bindings: {
        patient: "=",
        operationNoteList: "=",
        eventCallback: "&",
        registration: "&",
        filesCallback: "&",
        uploadOnly: "@",
        selectionCallback: "&",
    },
});

app.controller("patientFileTableController", [
    "$scope",
    "Session",
    "PatientAPI",
    "$modal",
    "ENV",
    "UploadFileAPI",
    "$filter",
    "NgTableParams",
    "FILE_TYPES",
    "ProgressNoteAPI",
    "OperationAPI",
    "MedicationAPI",
    "SurgeryAPI",
    function ($scope, Session, PatientAPI, $modal, ENV, UploadFileAPI,
        $filter,NgTableParams,FILE_TYPES, ProgressNoteAPI,OperationAPI,MedicationAPI,SurgeryAPI) {
            $scope.MAX_FILES_NUMBER = 4;
            this.$onInit = function () {
                $scope.fileAPI = UploadFileAPI;
                $scope.PA = PatientAPI;
                $scope.PN = ProgressNoteAPI;
                $scope.OperationAPI = OperationAPI;
                $scope.MedicationAPI = MedicationAPI;
                $scope.SurgeryAPI = SurgeryAPI;
                $scope.DocumentList = [];
                $scope.previewHead = $scope.$ctrl.uploadOnly ? 'Preview': '';
                this.registration({
                    fetch: this.fetchFiles,
                    preview: this.previewFile,
                  });
                fetchPatientDocument();
                // fetchUploadedFiles();
            };

            this.fetchFiles = function () {
                fetchPatientDocument();
            }

            this.previewFile = function (document_id, document_type, file_name) {
                $scope.previewPDF(document_id, document_type, file_name);
            }

            $scope.IMAGE_PDF_OPTIONS = FILE_TYPES.image;
            $scope.WORD_OPTIONS = FILE_TYPES.word;
            $scope.VIDEO_OPTIONS = FILE_TYPES.video;
    
            $scope.fileOptions = [];

            $scope.popoverDownload = {
                content:
                    "Download",
            };
            $scope.popoverAppend = {
                content:
                    "Append note",
            };
            $scope.popoverPreview = {
                content:
                    "Preview",
            };
    
            $scope.popoverPNEdit = {
                content:
                    "Edit",
            };
    
            $scope.popoverPNDelete = {
                content:
                    "Delete",
            };

            $scope.isDeleteEnabled = function(file) {
                if(Session.user.roles.includes('ADMIN')){
                    return true;
                }
                if(`${file.uploaded_by_user_id}` === Session.userId){
                    return true;
                }
                if(file.security_level === 'PRACTICE' || file.security_level === 'GLOBAL'){
                    return Session.practice.id === file.uploaded_by_practice_id && hasDeletePermission();
                }else if(file.security_level === 'OWNER'){
                    return `${file.uploaded_by_user_id}` === Session.userId;
                }
                return false;
            }
    
            function hasDeletePermission() {
                return Session.user.roles.some(t => ['ADMIN', 'REGISTRAR', 'OPHTHALMOLOGIST'].includes(t)); // remove practice_admin
            }
    
            $scope.popoverFileDelete = function (file) {
                return {
                    content:
                        $scope.isDeleteEnabled(file) ? "Delete" : "You do not have permission to delete this file",
                }
            };  

            $scope.popoverDocDelete = function (doc) {
                return {
                    content:
                        doc.document_deleted === true ? "Restore" : "Delete",
                }
            }; 
    
            $scope.popoverSentSuccess = function (doc) {
                return {
                    title: doc.hl7_msg_id,
                    content:
                        "Sent to EMR",
                }
            };
    
            $scope.popoverSentFail = function (doc) {
                return {
                    title: doc.hl7_msg_id,
                    content:
                        "Failed to send",
                }
            };
    
            $scope.popoverDeliverSuccess = function (doc) {
                return {
                    title: doc.hl7_msg_id,
                    content:
                        "Delivered to EMR",
                }
            };
    
            $scope.popoverDeliverFail = function (doc) {
                return {
                    title: doc.hl7_msg_id,
                    content: `Failed to deliver: ${doc.hl7_msg_error}`,
                }
            };
    
            $scope.popoverProcessing = function (doc) {
                return {
                    title: doc.hl7_msg_id,
                    content:
                        "Processing",
                }
            };
    
            $scope.showProcessing = function (doc) {
                return doc.hl7_msg_id !== null && doc.hl7_msg_status === 'PROCESSING'
            }
    
            $scope.showSentSuccess = function (doc) {
                return doc.hl7_msg_id !== null && doc.hl7_msg_status === 'SENT_OK'
            }
    
            $scope.showSentFail = function (doc) {
                return doc.hl7_msg_id !== null && doc.hl7_msg_status === 'SENT_FAIL'
            }
    
            $scope.showDeliverSuccess = function (doc) {
                return doc.hl7_msg_id !== null && doc.hl7_msg_status === 'ACK_ACCEPT'
            }
    
            $scope.showDeliverFail = function (doc) {
                return doc.hl7_msg_id !== null && (doc.hl7_msg_status === 'ACK_ERROR' || doc.hl7_msg_status === 'ACK_REJECTED')
            }    

            function downloadFile(data, filename, mime, bom) {
                // https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
                var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
                var blob = new Blob(blobData, { type: "application/pdf" });
                if (typeof window.navigator.msSaveBlob !== "undefined") {
                    // IE workaround for "HTML7007: One or more blob URLs were
                    // revoked by closing the blob for which they were created.
                    // These URLs will no longer resolve as the data backing
                    // the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var blobURL = window.URL.createObjectURL(blob);
                    var tempLink = document.createElement("a");
                    tempLink.style.display = "none";
                    tempLink.href = blobURL;
                    tempLink.setAttribute("download", filename);
    
                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === "undefined") {
                        tempLink.setAttribute("target", "_blank");
                    }
    
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }
            }
    
            function getFileName(document_type) {
                var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
                return (
                    `CatTrax_${document_type}_` +
                    theTime +
                    "_" +
                    $scope.$ctrl.patient.firstName +
                    "_" +
                    $scope.$ctrl.patient.lastName +
                    "_" +
                    $scope.$ctrl.patient.dateOfBirth +
                    ".pdf"
                );
            }

            function isImageFile(item) {
                var type = '|' + item.slice(item.lastIndexOf('.') + 1) + '|';
                var type2 = '|' + item.slice(item.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type.toLowerCase()) !== -1 || 
                '|jpg|png|jpeg|bmp|gif|'.indexOf(type2.toLowerCase()) !== -1;
            }
    
            function isPdfFile(item) {
                var type = '|' + item.slice(item.lastIndexOf('.') + 1) + '|';
                var type2 = '|' + item.slice(item.lastIndexOf('/') + 1) + '|';
                return '|pdf|'.indexOf(type.toLowerCase()) !== -1 || '|pdf|'.indexOf(type2.toLowerCase()) !== -1;
            }
    
            function isWordFile(item) {
                if(item.includes('text/plain')){
                    return true;
                }else if(item.includes("application/x-tika-ooxml")){
                    return true;
                }
                var type = '|' + item.slice(item.lastIndexOf('.') + 1).toLowerCase() + '|';
                var type2 = '|' + item.slice(item.lastIndexOf('/') + 1).toLowerCase() + '|';
                return '|vnd.openxmlformats-officedocument.wordprocessingml.document|docx|doc|'.indexOf(type) !== -1 || 
                '|vnd.openxmlformats-officedocument.wordprocessingml.document|docx|doc|'.indexOf(type2) !== -1;
            }
    
            function isVideoFile(item) {
                var type = '|' + item.slice(item.lastIndexOf('.') + 1) + '|';
                var type2 = '|' + item.slice(item.lastIndexOf('/') + 1) + '|';
                return '|avi|mp4|mov|quicktime|'.indexOf(type.toLowerCase()) !== -1 || 
                '|avi|mp4|mov|quicktime|'.indexOf(type2.toLowerCase()) !== -1;
            }
    
            function isTXTFile(item) {
                var type = '|' + item.slice(item.lastIndexOf('.') + 1) + '|';
                return '|txt|'.indexOf(type) !== -1;
            }

            $scope.downloadPDF = function (document_id, document_type,fileName) {
                if(document_type === 'upload'){
                    $scope.fileAPI.isProcessing = true;
                    if(isVideoFile(fileName)){
                        $scope.fileAPI.fetchVideoUrl($scope.$ctrl.patient.id, document_id).then(data=> {
                            $scope.fileAPI.downloadVideoFile(data.data).then(function (resp) {
                                downloadFile(resp.data, fileName);
                            }, function (error) {
                                // TODO
                            }).then(function(){
                                $scope.fileAPI.isProcessing = false;
                            });
                            
                        });
                    }else{
                        $scope.fileAPI.downloadFile($scope.$ctrl.patient.id, document_id).then(function (resp) {
                            downloadFile(resp.data, fileName);
                        }, function (error) {
                            // TODO
                        }).then(function(){
                            $scope.fileAPI.isProcessing = false;
                        });
                    }
                    return;
                }
                $scope.PA.downloadDocument($scope.$ctrl.patient.id, document_id, document_type).then(function (resp) {
                    downloadFile(resp.data, getFileName(document_type));
                }, function (err) {
                    console.log(err);
                    $scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
                });
            };

        $scope.showFileUploaderModal = function () {
			$scope.isFileUnsaved = false;
			$scope.fileUploaderModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.files-uploader.tpl.html",
				show: true,
				title: "UPLOAD FILES",
				backdrop: "static",
				keyboard: false,
			});
		}

        $scope.isFileUnsaved = false;
		$scope.fileUploaderCallback = function (unsaved, close){
			$scope.isFileUnsaved = unsaved;
            
			if(close){
				$scope.isFileUnsaved = false;
				$scope.closeFileUploaderModal();
			}
           
		}

        $scope.closeFileUploaderModal = function () {
			if ($scope.fileUploaderModal && !$scope.isFileUnsaved) {
				$scope.fileUploaderModal.hide();
				fetchPatientDocument();
				return;
			}
			$scope.alertMessage = 'Are you sure to quit file upload ?';
			$scope.errorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.file-upload-error.tpl.html",
                show: true,
                title: "Files are not uploaded yet",
            });
		}

        $scope.closeFileUploadErrorModal = function () {
			if('Are you sure to quit file upload ?' === $scope.alertMessage){
				$scope.fileUploaderModal.hide();
			}
			$scope.alertMessage = null; 
			$scope.errorModal.hide();
		}

        function upperCaseFirstLetter(text) {
			if (!text) {
				return "";
			}
			if (text.startsWith("oct macula")) {
				return "OCT Macula";
			} else if (text.startsWith("OCT glaucoma")) {
				return "OCT Glaucoma";
			} else if (text.startsWith("OCT anterior segment")) {
				return "OCT Anterior Segment";
			}
			const words = text.split(" ");
			return words
				.map((word) => {
					return word[0].toUpperCase() + word.substring(1);
				})
				.join(" ");
		}

        const reg = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{2}$');
		function applyComparer(actual, expected) {
			if (expected === "Phaco and IOL") {
				return angular.equals(actual, expected)
			}
			if (reg.test(actual)) {
				return angular.equals(actual.slice(-2), expected)
			}
			return actual.toLowerCase().includes(expected.toLowerCase()) // self.comparer.fn(actual, expected);
		}
        function convertSecurityLevel(level, uploaded_by_user_name, uploaded_by_practice_name) {
			if (level === 'GLOBAL') {
				return 'Patient record';
			} else if (level === 'OWNER') {
				return uploaded_by_user_name;
			} else if (level === 'PRACTICE') {
				return uploaded_by_practice_name;
			}
			return '';
		}

        $scope.checkboxes = {
            items: {}
        };
        $scope.selectedFilesError = false;

        $scope.$watch(
            "checkboxes",
            function (newValue, oldValue) {
                if (newValue && $scope.$ctrl.selectionCallback) {
                    const array = Object.entries(newValue.items);
                    const selected = array.filter(t=>t[1] === true).length;
                    if( selected > $scope.MAX_FILES_NUMBER) {
                        $scope.checkboxes = oldValue;
                        $scope.selectedFilesError = true;
                        return;
                    }else if($scope.selectedFilesError && selected < $scope.MAX_FILES_NUMBER) {
                        $scope.selectedFilesError = false;
                    }
                    $scope.$ctrl.selectionCallback({selection: newValue});
                }
            },
            true
        );

        function fetchPatientDocument() {
			if (!$scope.$ctrl.patient || !$scope.$ctrl.patient.id) {
				return;
			}
            
            if($scope.$ctrl.uploadOnly){
                const newData = [];
                $scope.DocumentList = newData;
                $scope.tableParams = new NgTableParams({
                    count: 10
                }, {
                    counts: [],
                    filterOptions: { filterComparator: applyComparer },
                    dataset: newData,
                });
                fetchUploadedFiles();
                return;
            }
			$scope.PA.isProcessing = true;
			$scope.PA.fetchDocument($scope.$ctrl.patient.id)
				.then(
					function (resp) {
						const newData = resp.data.map(t => {
							return {
								...t,security_level:t.document_security_type, security_level_text: convertSecurityLevel(t.document_security_type,'',Session.practice.name), pathway: 'Cataract',date_time: new Date(`${t.document_created_date.substring(3, 5)}/${t.document_created_date.substring(0, 2)}/${t.document_created_date.substring(6, 8)} ${t.document_created_time}`), created_date: new Date(`${t.document_created_date.substring(3, 5)}/${t.document_created_date.substring(0, 2)}/${t.document_created_date.substring(6, 8)}`)
							}
						}).sort(function compareFn(a, b) { return b.date_time.getTime() - a.date_time.getTime() });
						$scope.DocumentList = newData;					
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function (obj) {
                    $scope.tableParams = new NgTableParams({
                        count: 10
                    }, {
                        counts: [],
                        filterOptions: { filterComparator: applyComparer },
                        dataset: $scope.DocumentList,
                    });
                    $scope.documentFilter = $scope.DocumentList.reduce(function (accumulator, currentValue) {
                        if (!accumulator.some(t => t.title === currentValue.document_name)) {
                            accumulator.push({ id: currentValue.document_name, title: currentValue.document_name })
                        }
                        return accumulator
                    }, [{ id: '', title: 'All' }])

                    $scope.dateArray = $scope.DocumentList.reduce(function (accumulator, currentValue) {
                        if (!accumulator.some(t => t.title === '20' + currentValue.document_created_date.slice(-2))) {
                            accumulator.push({ id: currentValue.document_created_date.slice(-2), title: '20' + currentValue.document_created_date.slice(-2) })
                        }
                        return accumulator
                    }, [{ id: '', title: 'All' }])
					fetchUploadedFiles();
					$scope.PA.isProcessing = false;
				});
		}

        function fetchUploadedFiles() {
			$scope.fileAPI.isProcessing = true;
			const list = [];
            $scope.fileAPI.getFiles($scope.$ctrl.patient.id).then(function (resp) {
				resp.data.forEach(t => {
					if(t.file_type){
						const item = {
							document_name: upperCaseFirstLetter(t.file_type.toLowerCase().replaceAll('_',' ')) + ` ${t.eye}`,
							document_created_date: $filter("date")(new Date(t.date_of_consultation), "dd/MM/yy"),
							document_created_time: '', //$filter("date")(new Date(t.date_of_consultation), "hh:mm a"),
							// document_created_by_name: '',
							document_type: 'upload',
							document_available: true,
							document_id: t.file_id,
							patient_id: t.patient_id,
							pathway: 'Cataract',
							date_time: new Date(t.date_of_consultation),
							created_date: t.date_of_consultation,
							file_name: t.full_name,
							security_level: t.security_level,
							security_level_text: convertSecurityLevel(t.security_level, t.uploaded_by_user_name, t.uploaded_by_practice_name),
							file_upload_date: t.date_uploaded,
							comments: t.comments.length > 0 ? t.comments : '',
							eye: t.eye,
							file_type: t.file_type,
							uploaded_by_practice_id: t.uploaded_by_practice_id,
							uploaded_by_user_id: t.uploaded_by_user_id,
							uploaded_by_user_roles: t.uploaded_by_user_roles,
							uploaded_by_practice_name: t.uploaded_by_practice_name,
							uploaded_by_user_name: t.uploaded_by_user_name,
							file_mime_type: t.metadata.file_mime_type,
						};
                        if(item.file_type == 'VIDEO' && $scope.$ctrl.uploadOnly){
                            return;
                        }
						$scope.DocumentList.push(item);
						list.push(item);
					}
				});
				// $scope.uploadedDocuments = list;
                if($scope.$ctrl.filesCallback){
                    $scope.$ctrl.filesCallback({uploadedDocuments: angular.copy(list)});
                }

                $scope.DocumentList.forEach((t) => {
					$scope.checkboxes.items[t.document_id] = false;
				});
                
				$scope.DocumentList = $scope.DocumentList.sort(function compareFn(a, b) { return b.date_time.getTime() - a.date_time.getTime() });
				$scope.documentFilter = $scope.DocumentList.reduce(function (accumulator, currentValue) {
					if (!accumulator.some(t => t.title === currentValue.document_name)) {
						accumulator.push({ id: currentValue.document_name, title: currentValue.document_name })
					}
					return accumulator
				}, [{ id: '', title: 'All' }])

				$scope.dateArray = $scope.DocumentList.reduce(function (accumulator, currentValue) {
					if (!accumulator.some(t => t.title === '20' + currentValue.document_created_date.slice(-2))) {
						accumulator.push({ id: currentValue.document_created_date.slice(-2), title: '20' + currentValue.document_created_date.slice(-2) })
					}
					return accumulator
				}, [{ id: '', title: 'All' }])
				$scope.tableParams.reload();
            }, function (error) {
                // TODO
            }).then(function(){
                $scope.fileAPI.isProcessing = false;
            });
		}

        $scope.previewPDF = function (document_id, document_type, fileName) {
			if (document_type === 'upload') {
				if (isPdfFile(fileName)) {
					$scope.pdfUrl = ENV.API + `/api/files/patient/${$scope.$ctrl.patient.id}/file/${document_id}`;
					$scope.httpHeaders = { Authorization: `Bearer ${Session.token}`, "X-PRACTICE-ID": `${Session.practice.id}` };
					$scope.scroll = 0;
					$scope.loading = 'loading';
					$scope.onLoad = function () {
						$scope.loading = '';
						setTimeout(()=>{
							angular.element('#pdf_zoom_in').trigger("click");
							angular.element('#pdf_zoom_out').trigger("click");
						},500);
					}
					$scope.pdfViewerModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.pdf-viewer.tpl.html",
						show: true,
						title: "DOCUMENT PREVIEW",
						backdrop: true,
						keyboard: false,
					});
				}else if(isVideoFile(fileName)){
					$scope.videoViewerModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.video-viewer.tpl.html",
						show: true,
						title: "VIDEO PREVIEW",
						backdrop: true,
						keyboard: false,
					});
					$scope.fileAPI.fetchVideoUrl($scope.$ctrl.patient.id, document_id).then(data=> {
                        if(data.status === 202){
                        $scope.videoUrl = data.data; // 'https://drive.google.com/uc?id=1NmkSdk08ivJEIJ6NOTo6LqLVsw_PmlVU&export=download&authuser=0';
                        $scope.config = {
                            preload: "none",
                            sources: [
                                { src: $scope.videoUrl, type: "video/mp4" },
                            ],
                            tracks: [],
                            theme: {
                                url: "https://unpkg.com/videogular@2.1.2/dist/themes/default/videogular.css"
                            },
                            plugins: {
                                poster: "https://www.cattrax.co.nz/app/build/img/cattrax_logo.png"
                            }
                        };
                        }else{
                            //error
                        }
                    });
				}else if(isImageFile(fileName)){
					$scope.fileAPI.downloadFile($scope.$ctrl.patient.id, document_id).then(function (resp) {
						$scope.imageFileUrl = URL.createObjectURL(new Blob([resp.data], {type: "octet/stream"}));
						$scope.imageViewerModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.image-viewer.tpl.html",
							show: true,
							title: "IMAGE PREVIEW",
							backdrop: true,
							keyboard: false,
						});
					}, function (error) {
						// TODO
					}).then(function () {
						$scope.fileAPI.isProcessing = false;
					});
				}else if(isWordFile(fileName)){
					$scope.fileAPI.downloadFile($scope.$ctrl.patient.id, document_id).then(function (resp) {
						$scope.wordViewerModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.word-viewer.tpl.html",
							show: true,
							title: "DOCUMENT PREVIEW",
							backdrop: true,
							keyboard: false,
						});
						setTimeout(()=>{
							handleFileSelect(new Blob([resp.data], {type: "octet/stream"}), false);
						},500);
					}, function (error) {
						// TODO
					}).then(function () {
						$scope.fileAPI.isProcessing = false;
					});
				} else if(isTXTFile(fileName)){
					$scope.fileAPI.downloadFile($scope.$ctrl.patient.id, document_id).then(function (resp) {
						$scope.wordViewerModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.word-viewer.tpl.html",
							show: true,
							title: "DOCUMENT PREVIEW",
							backdrop: true,
							keyboard: false,
						});
						setTimeout(()=>{
							handleFileSelect(new Blob([resp.data], {type: "octet/stream"}), true);
						},500);
					}, function (error) {
						// TODO
					}).then(function () {
						$scope.fileAPI.isProcessing = false;
					});

				}
				return;
			}
			$scope.pdfUrl = ENV.API + `/api/documents/patient/${$scope.$ctrl.patient.id}/document/${document_type}/${document_id}`;
			$scope.httpHeaders = { Authorization: `Bearer ${Session.token}`, "X-PRACTICE-ID": `${Session.practice.id}`};
			$scope.scroll = 0;
			$scope.loading = 'loading';
			$scope.onLoad = function () {
				$scope.loading = '';
				setTimeout(()=>{
					angular.element('#pdf_zoom_in').trigger("click");
					angular.element('#pdf_zoom_out').trigger("click");
				},500);
			}
			$scope.pdfViewerModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.pdf-viewer.tpl.html",
				show: true,
				title: "DOCUMENT PREVIEW",
				backdrop: true,
				keyboard: false,
			});
		};

        $scope.getNavStyle = function (scroll) {
			if (scroll > 100) return 'pdf-controls fixed';
			else return 'pdf-controls';
		}

		$scope.closePdfViewer = function () {
			if($scope.pdfViewerModal){
				$scope.pdfViewerModal.hide();
			}
		}

		$scope.onError = function (error) {
			console.log(error);
			if ($scope.pdfViewerModal) {
				$scope.pdfViewerModal.hide();
				$scope.pdfViewerModal = null;
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}
		}

		$scope.onLoad = function () {
			$scope.loading = '';
		}

        $scope.showAppendButton = function (doc) {
			const documentId = doc.document_id;
			if (doc.document_type === 'INJECTION_NOTE') {
				let eye = "OD/OS";
				if(doc.document_name.endsWith('OD')){
					eye = "OD";
				}else if(doc.document_name.endsWith('OS')){
					eye = "OS"
				}
				$scope.DocumentList.find(t => t.document_id === documentId);

                $scope.$ctrl.eventCallback({document: {
                    operative_eye: eye === "OD/OS" ? "BOTH" : eye,
                    disableSelect: eye === "OD/OS" ? false : true,
                    operationNote: {
                        injection_note_id: documentId,
                        patient_id: $scope.$ctrl.patient.id,
                        op_note_od: { },
                        op_note_os: { },
                    }
                }});
			} else if (doc.document_type === 'OPNOTE') {
				const note = $scope.$ctrl.operationNoteList.find(t => t.operation_note_id === documentId);
				if (!note) {
					console.error(`cannot find operation note ${documentId}`);
					note = { eye: "OD/OS" }
				}

                $scope.$ctrl.eventCallback({document: {
                    operative_eye:  note.eye === "OD/OS" ? "BOTH" : note.eye,
                    disableSelect: note.eye === "OD/OS" ? false : true,
                    operationNote: {
                        operation_note_id: documentId,
                        patient_id: $scope.$ctrl.patient.id,
                        op_note_od: { operative_eye: "NO" },
                        op_note_os: { operative_eye: "NO" },
                    }
                }});
			}
		};

        $scope.closeVideoViewer = function () {
            if($scope.videoViewerModal){
				$scope.videoViewerModal.hide();
			}
            if($scope.videoUrl){
                URL.revokeObjectURL($scope.videoUrl);
                $scope.videoUrl = undefined;
            }
        }

		$scope.closeImageViewer = function () {
            if($scope.imageViewerModal){
				$scope.imageViewerModal.hide();
			}
            if($scope.imageFileUrl){
                URL.revokeObjectURL($scope.imageFileUrl);
                $scope.imageFileUrl = undefined;
            }
        }

		function handleFileSelect(file, isTXT) {
			if (isTXT) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    const content = document.querySelector(".content");
                    content.innerText = loadEvent.target.result;
                };
                reader.readAsText(file);
                return;
            }
            readFileInputEventAsArrayBuffer(file, function(arrayBuffer) {
                mammoth.convertToHtml({arrayBuffer: arrayBuffer})
                    .then(displayResult, function(error) {
                        console.error(error);
                    });
            });
        }
    
        function displayResult(result) {
            document.getElementById("output").innerHTML = result.value;
    
            var messageHtml = result.messages.map(function(message) {
                return '<li class="' + message.type + '">' + escapeHtml(message.message) + "</li>";
            }).join("");
    
            document.getElementById("messages").innerHTML = "<ul>" + messageHtml + "</ul>";
        }
    
        function readFileInputEventAsArrayBuffer(file, callback) {
            var reader = new FileReader();
    
            reader.onload = function(loadEvent) {
                var arrayBuffer = loadEvent.target.result;
                callback(arrayBuffer);
            };
    
            reader.readAsArrayBuffer(file);
        }
    
        function escapeHtml(value) {
            return value
                .replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
        }

        $scope.filter = {
			date: false,
			document: false,
			pathway: false,
			clinician: false
		}

		$scope.triggerFilterDate = function(){
			$scope.filter.date = !$scope.filter.date;
			if(!$scope.filter.date){
				$scope.tableParams.filter()['document_created_date'] = '';
			}
		}

		$scope.triggerFilterDocument = function(){
			$scope.filter.document = !$scope.filter.document;
			if(!$scope.filter.document){
				$scope.tableParams.filter()['document_name'] = '';
			}
		}

		$scope.triggerFilterPathway = function(){
			$scope.filter.pathway = !$scope.filter.pathway;
			if(!$scope.filter.pathway){
				$scope.tableParams.filter()['pathway'] = '';
			}
		}

		$scope.triggerFilterClinician = function(){
			$scope.filter.clinician = !$scope.filter.clinician;
			if(!$scope.filter.clinician){
				$scope.tableParams.filter()['document_created_by_name'] = '';
			}
		}

        $scope.deleteFileItem = null;

        $scope.deleteFileConfirm = function() {
            $scope.errorModal.hide();
            $scope.fileAPI.isProcessing = true;
            $scope.fileAPI.deleteFile($scope.$ctrl.patient.id, $scope.deleteFileItem).then(function (resp) {
                // do nothing
            }, function (error) {
                // TODO
            }).then(function(){
                $scope.fileAPI.isProcessing = false;
                $scope.deleteFileItem = null;
                fetchPatientDocument();
            });
        }

        $scope.clearFileDeleteConfirm = function(){
            $scope.deleteFileItem = null;
            $scope.errorModal.hide();
        }

        $scope.deleteFile = function (fileId, fileName) {
            if ($scope.deleteFileItem == null) {
                $scope.deleteFileItem = fileId;
                $scope.errorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.file-upload-delete.tpl.html",
                    show: true,
					title: `Delete ${fileName}`,
                });
                return;
            }
        }

		$scope.updateFile = function(file) {
            if (!file.file_upload_date) {
				// progress note
				$scope.tooltipHide = true;
				$scope.PN
					.updateVisibility(
						$scope.$ctrl.patient.id,
						file.document_id,
						file.document_security_type === "PRACTICE" ? "GLOBAL" : "PRACTICE"
					)
					.then(
						function (resp) {
							fetchPatientDocument();
						},
						function (error) {
							// TODO
						}
					)
					.then(function () {
						$scope.tooltipHide = false;
					});
				return;
			}


			$scope.tooltipHide = true;
			$scope.selectedFile = file;
			$scope.newFileItem = {
				fileType: file.file_type,
				operative_eye: file.eye,
				dateOfConsultation: $filter("date")(file.date_time, "dd/MM/yyyy"),
				security_level: file.security_level,
			};
			
			if (isImageFile(file.file_mime_type) || isPdfFile(file.file_mime_type)) {
                $scope.fileOptions = $scope.IMAGE_PDF_OPTIONS;
            } else if (isWordFile(file.file_mime_type)) {
                $scope.fileOptions = $scope.WORD_OPTIONS;
            } else if (isVideoFile(file.file_mime_type)) {
                $scope.fileOptions = $scope.VIDEO_OPTIONS;
            } else {
				$scope.fileOptions = [];
			}
			const selectedType = $scope.fileOptions.find(t=>t.id === file.file_type);
			$scope.fileItem_fileType = {id:selectedType.id,name:selectedType.name};
			$scope.detailsModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.file-upload-details.tpl.html",
                controller: "fileDetailController",
                show: true,
                backdrop: "static",
                title: "Update File Details",
                resolve: {
                    newFileItem: () => $scope.newFileItem,
                    fileType: () => $scope.fileItem_fileType,
                    fileOptions: () => $scope.fileOptions,
                    roles: () => file.uploaded_by_user_roles,
                  }
				// windowClass: 'modal-zindex',
            });
		}

		$scope.cancelFileUpdate = function () {
			$scope.tooltipHide = false;
			$scope.detailsModal.hide();
		}

		$scope.updateFileConfirm = function () {
			let body = {};

			if($scope.selectedFile.file_type !== $scope.newFileItem.fileType){
				body = {...body, file_type: $scope.newFileItem.fileType};
			}

			if($scope.selectedFile.eye !== $scope.newFileItem.operative_eye){
				body = {...body, eye: $scope.newFileItem.operative_eye};
			}

			if($scope.selectedFile.security_level !== $scope.newFileItem.security_level){
				body = {...body, security_level: $scope.newFileItem.security_level};
			}

			if ($scope.newFileItem.comment) {
				body = { ...body, comments: [{ comment: $scope.newFileItem.comment }] };
			}

			if(Object.keys(body).length === 0){
				$scope.tooltipHide = false;
				$scope.detailsModal.hide();
				return;
			}

			$scope.fileAPI.isProcessing = true;
			$scope.fileAPI.updateFile($scope.$ctrl.patient.id, $scope.selectedFile.document_id, body).then(function (resp) {
                fetchPatientDocument();
			}, function (error) {
				// TODO
			}).then(function () {
				$scope.fileAPI.isProcessing = false;
				$scope.tooltipHide = false;
				$scope.detailsModal.hide();
			});
		}

        $scope.isDeleteVisible = function(document_type){
            return ['OPNOTE','PRESCRIPTION_NOTE','WORKUP'].includes(document_type);
        }

		$scope.getDeleteSrc = function (document_created_by_id) {
			if (`${Session.userId}` === `${document_created_by_id}`) {
				return "app/build/img/delete.svg";
			}
			return "app/build/img/delete-disabled.svg";
		};

		$scope.getRestoreSrc = function (document_created_by_id) {
			if (`${Session.userId}` === `${document_created_by_id}`) {
				return "app/build/img/restore.svg";
			}
			return "app/build/img/restore-disabled.svg";
		};

        $scope.restoreDoc = function (document_type, document_id, document_created_by_id) {
			if(`${Session.userId}` !== `${document_created_by_id}`){
				return;
			}
			if ($scope.OperationAPI.isProcessing || $scope.MedicationAPI.isProcessing || $scope.SurgeryAPI.isProcessing) {
				return;
			}
			if (document_type === "OPNOTE") {
				$scope.OperationAPI.isProcessing = true;
				$scope.OperationAPI.restoreOpNote($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.OperationAPI.isProcessing = false));
			} else if (document_type === "PRESCRIPTION_NOTE") {
				$scope.MedicationAPI.isProcessing = true;
				$scope.MedicationAPI.restorePrescription($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.MedicationAPI.isProcessing = false));
			} else if ("WORKUP" === document_type) {
				$scope.SurgeryAPI.isProcessing = true;
				$scope.SurgeryAPI.restoreWorkup($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.SurgeryAPI.isProcessing = false));
			}
		};

		$scope.deleteDoc = function (document_type, document_id, document_created_by_id) {
			if(`${Session.userId}` !== `${document_created_by_id}`){
				return;
			}
			if ($scope.OperationAPI.isProcessing || $scope.MedicationAPI.isProcessing || $scope.SurgeryAPI.isProcessing) {
				return;
			}
			if (document_type === "OPNOTE") {
				$scope.OperationAPI.isProcessing = true;
				$scope.OperationAPI.deleteOpNote($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.OperationAPI.isProcessing = false));
			} else if (document_type === "PRESCRIPTION_NOTE") {
				$scope.MedicationAPI.isProcessing = true;
				$scope.MedicationAPI.deletePrescription($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.MedicationAPI.isProcessing = false));
			} else if ("WORKUP" === document_type) {
				$scope.SurgeryAPI.isProcessing = true;
				$scope.SurgeryAPI.deleteWorkup($scope.$ctrl.patient.id, document_id)
					.then(
						function () {
							fetchPatientDocument();
						},
						function (e) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					)
					.then(() => ($scope.SurgeryAPI.isProcessing = false));
			}
		};
    }])
