app.controller("operationNoteController", [
	"$scope",
	"OperationNote",
	"OperationAPI",
	"NHI",
	"$timeout",
	"Session",
	"CurrentPatient",
	"$state",
	"$filter",
	"$q",
	"$modal",
	"UserService",
	"OPERATIONPROCEDURE",
	"SurgeryAPI",
	"PreOp",
	"$window",
	"ShareData",
	"OPNOTE_SAVE_ALL_SETTINGS_1020",
	function (
		$scope,
		OperationNote,
		OperationAPI,
		NHI,
		$timeout,
		Session,
		CurrentPatient,
		$state,
		$filter,
		$q,
		$modal,
		UserService,
		OPERATIONPROCEDURE,
		SurgeryAPI,
		PreOp,
		$window,
		ShareData,
		OPNOTE_SAVE_ALL_SETTINGS_1020
	) {
		$scope.CP = CurrentPatient;
		$scope.isHistoryCollapsed = true;
		$scope.isPlanCollapsed = true;
		$scope.displaySecondaryNHI = false;
		$scope.displaySecondaryInsurance = false;
		$scope.visionAlerts = [];
		$scope.historyAlerts = [];
		$scope.planAlerts = [];
		// $scope.alertMessage = "Data saved, complete all sections to finalise the surgical plan.";
		$scope.nhi = {};
		$scope.operative_eye = "";
		$scope.showCommentsOnly = false;
		$scope.displayContactTracingMessageInButtonSurround = false;
		$scope.ophthalmologists = [];
		$scope.isSubmitted = false;
		$scope.hiddenFooter = false;
		$scope.noteDetails = "";
		$scope.disableSelect = false;
		$scope.operationNoteHeader = "";
		$scope.displaySpinner = false;
		$scope.remoteDefaultSetting = null;
		$scope.PreOp = null;
		$scope.noteContent = "operation note";
		$scope.operation_note_other_procedure_settings = [];

		$scope.closeInsuranceModal = function () {
			$scope.displaySecondaryInsurance = false;
			if ($scope.CP.patient.insuranceProvider) {
				$scope.submit();
			}
		}

		$scope.US = UserService;
		$scope.US.getDoctorsByPracticeId(Session.practice.id).then(
			function (resp) {
				$scope.ophthalmologists = [];
				resp.data.forEach(function (doc) {
					$scope.ophthalmologists.push({ name: doc.firstName + " " + doc.lastName, value: doc.id });
				});
				$scope.ophthalmologists.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});

			},
			function (err) {
				console.log(err);
			}
		).then(function () {
			requestOperationNote();
		});

		function isMergeSettingNeeded(operative_eye) {
			if (operative_eye === "OD") {
				return !$scope.OperationNote.op_note_od.procedure_type;
			} else if (operative_eye === "OS") {
				return !$scope.OperationNote.op_note_os.procedure_type;
			}
			return false;
		}

		$scope.$watch(
			"operative_eye",
			function (newValue) {
				if (newValue === "OD") {
					$scope.OperationNote.op_note_od.operative_eye = "YES";
					$scope.OperationNote.op_note_os.operative_eye = "NO";
					if (OPNOTE_SAVE_ALL_SETTINGS_1020 && isMergeSettingNeeded('OD')) {
						mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, "OD");
					}
				} else if (newValue === "OS") {
					$scope.OperationNote.op_note_os.operative_eye = "YES";
					$scope.OperationNote.op_note_od.operative_eye = "NO";
					if (OPNOTE_SAVE_ALL_SETTINGS_1020 && isMergeSettingNeeded('OS')) {
						mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, "OS");
					}
				} else if (newValue === "BOTH") {
					$scope.OperationNote.op_note_od.operative_eye = "YES";
					$scope.OperationNote.op_note_os.operative_eye = "YES";
					if (OPNOTE_SAVE_ALL_SETTINGS_1020 && isMergeSettingNeeded('OD')) {
						mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, "OD");
					}
					if (OPNOTE_SAVE_ALL_SETTINGS_1020 && isMergeSettingNeeded('OS')) {
						mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, "OS");
					}
				}
			},
			true
		);

		function cutUnusedproperties(newValue, eye) {
			if(newValue && !['ABORTED_PROCEDURE','PHACO_IOL','PHACO_NO_IOL','IOL_IMPLANTATION'].includes(newValue)){
				// cut ccc
				if(eye === 'os'){
					delete $scope.OperationNote.op_note_os.ccc;
				}else{
					delete $scope.OperationNote.op_note_od.ccc;
					console.log(`${JSON.stringify($scope.OperationNote.op_note_od)}`)
				}

			}
			
			if(newValue && !['ABORTED_PROCEDURE','PHACO_IOL','PHACO_NO_IOL'].includes(newValue)){
				// cut phaco
				if(eye === 'os'){
					delete $scope.OperationNote.op_note_os.phaco;
				}else{
					delete $scope.OperationNote.op_note_od.phaco;
				}

			}
           
		}

		$scope.$watch("OperationNote.op_note_os.procedure_type", (newValue)=>cutUnusedproperties(newValue,'os'), true);
		$scope.$watch("OperationNote.op_note_od.procedure_type", (newValue)=>cutUnusedproperties(newValue,'od'), true);



		$scope.$watch("OperationNote.surgeon_user_id", requestDefaultSetting, true);

		// $scope.$watch("OperationNote.op_note_od.procedure_type", requestDefaultSetting, true);

		// $scope.$watch("OperationNote.op_note_os.procedure_type", requestDefaultSetting, true);

		$scope.$watch("OperationNote.op_note_od.ccc.technique_type", common, true);
		$scope.$watch("OperationNote.op_note_os.ccc.technique_type", common, true);

		$scope.$watch("OperationNote.op_note_od.iol.lens_placement", common, true);
		$scope.$watch("OperationNote.op_note_os.iol.lens_placement", common, true);

		$scope.$watch("OperationNote.op_note_od.phaco.technique", common, true);
		$scope.$watch("OperationNote.op_note_os.phaco.technique", common, true);

		$scope.$watch("OperationNote.op_note_od.phaco.irrigation", common, true);
		$scope.$watch("OperationNote.op_note_os.phaco.irrigation", common, true);

		$scope.saveHistory = function (isWorkup) {
			if (!$scope.history.$valid && isWorkup) {
				$scope.isPlanCollapsed = true;
				throw new Error("error in history section");
			}
			return null;
		};

		$scope.submit = function () {
			$scope.isSubmitted = true;
			const valid = checkValid();
			if (!valid) return;

			// display NHI warning if value is not present
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}

			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}

			if (!$scope.showWarning) {
				$scope.showWarning = true;
				return;
			}

			if ($scope.OperationNote.op_note_od && $scope.OperationNote.op_note_od.operative_eye === 'NO') {
				$scope.OperationNote.op_note_od = null;
			}
			if ($scope.OperationNote.op_note_os && $scope.OperationNote.op_note_os.operative_eye === 'NO') {
				$scope.OperationNote.op_note_os = null;
			}

			$scope.displaySpinner = true;
			$scope.isSuccess = false;
			$scope.processingWorkup = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.processing-operation-note-workup.tpl.html",
				show: true,
				title: "PROCESSING...",
				keyboard: false,
				backdrop: "static",
			});
			OperationAPI.submitWorkUp(dataTransform(),$scope.opNoteOption)
				.then(function (resp) {
					downloadFile(resp.data, getFileName());
					$scope.$broadcast('trigger:automation-task');
					$scope.processingWorkup.hide();
					$scope.isSuccess = true;
					$scope.successfulWorkup = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.processing-operation-note-workup.tpl.html",
						show: true,
						title: "SUCCESS",
						keyboard: false,
						backdrop: "static",
					});
				})
				.catch(function (error) {
					console.error(`submitWorkUp error ${JSON.stringify(error)}`);
					$scope.displaySpinner = false;
					if($scope.processingWorkup){
						$scope.processingWorkup.hide();
					}
					
					$scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
				});
		};

		$scope.savePlan = function (isWorkup) {
			return null;
		};

		function dataTransform() {
			const body = angular.copy($scope.OperationNote);
			if (body.op_note_od && body.op_note_od.ccc && body.op_note_od.ccc.dilated_pupil_size) {
				if (
					typeof body.op_note_od.ccc.dilated_pupil_size === "string" &&
					body.op_note_od.ccc.dilated_pupil_size.indexOf("mm") !== -1
				) {
					//do nothing
				} else if (Number.isInteger(body.op_note_od.ccc.dilated_pupil_size)) {
					body.op_note_od.ccc.dilated_pupil_size = "_" + body.op_note_od.ccc.dilated_pupil_size + "mm";
				} else {
					body.op_note_od.ccc.dilated_pupil_size = "_" + Math.floor(body.op_note_od.ccc.dilated_pupil_size) + "_5mm";
				}
			}
			if (body.op_note_os && body.op_note_os.ccc && body.op_note_os.ccc.dilated_pupil_size) {
				if (
					typeof body.op_note_os.ccc.dilated_pupil_size === "string" &&
					body.op_note_os.ccc.dilated_pupil_size.indexOf("mm") !== -1
				) {
					//do nothing
				} else if (Number.isInteger(body.op_note_os.ccc.dilated_pupil_size)) {
					body.op_note_os.ccc.dilated_pupil_size = "_" + body.op_note_os.ccc.dilated_pupil_size + "mm";
				} else {
					body.op_note_os.ccc.dilated_pupil_size = "_" + Math.floor(body.op_note_os.ccc.dilated_pupil_size) + "_5mm";
				}
			}
			if (body.op_note_od && body.op_note_od.ccc && !body.op_note_od.ccc.technique_type) {
				body.op_note_od.ccc.technique_type = "";
			}
			if (body.op_note_os && body.op_note_os.ccc && !body.op_note_os.ccc.technique_type) {
				body.op_note_os.ccc.technique_type = "";
			}

			if (body.op_note_od && body.op_note_od.procedure_type === 'OTHER') {
				delete body.op_note_od.iol;
				body.op_note_od.adjuvant_procedures = {
					planned_anterior_vitrectomy: null,
					sulcus: null,
					capsular_tension_ring: null,
					intravitreal_injection: null,
					migs: null,
					other: null
				};
			}else if(body.op_note_od && body.op_note_od.procedure_type !== 'OTHER'){
				delete body.op_note_od.other_procedure;
			}

			if (body.op_note_os && body.op_note_os.procedure_type === 'OTHER') {
				delete body.op_note_os.iol;
				body.op_note_os.adjuvant_procedures = {
					planned_anterior_vitrectomy: null,
					sulcus: null,
					capsular_tension_ring: null,
					intravitreal_injection: null,
					migs: null,
					other: null
				};
			} else if(body.op_note_os && body.op_note_os.procedure_type !== 'OTHER') {
				delete body.op_note_os.other_procedure;
			}


			return body;
		}

		function cutRequestForSetting() {
			const body = angular.copy($scope.OperationNote);

			const shouldSkip_od = OPNOTE_SAVE_ALL_SETTINGS_1020 ? false : !["PHACO_IOL", "OTHER"].includes(body.op_note_od.procedure_type);
			const shouldSkip_os = OPNOTE_SAVE_ALL_SETTINGS_1020 ? false : !["PHACO_IOL", "OTHER"].includes(body.op_note_os.procedure_type);


			if (body.op_note_od && shouldSkip_od) {
				body.op_note_od = null;
			}

			if (body.op_note_od && body.op_note_od.ccc) {
				body.op_note_od.ccc.dilated_pupil_size = null;
			}

			if (body.op_note_os && shouldSkip_os) {
				body.op_note_os = null;
			}

			if (body.op_note_os && body.op_note_os.ccc) {
				body.op_note_os.ccc.dilated_pupil_size = null;
			}
			
			return body;
		}

		$scope.downloadPDF = function (note) {
			OperationAPI.downloadPDF(note.patient_id, note.operation_note_id).then(function (resp) {
				downloadFile(resp.data, getFileName());
			});
		};

		$scope.submitWorkUp = function () {
			// display NHI warning if value is not present
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}

			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}
		};

		$scope.closeAlert = function (index, arr) {
			arr.splice(index, 1);
		};

		$scope.returnToSearch = function () {
			$scope.successfulWorkup.hide();
			$scope.CP.clearPatientResult();
			$state.go("refer.search");
		};

		$scope.sugicalHistory = function () {
			$scope.successfulWorkup && $scope.successfulWorkup.hide();
			$scope.remoteDefaultSetting = null;
			// requestOperationNote();
			$window.history.back();
		};

		$scope.showAppendButton = function (note) {
			$scope.showCommentsOnly = true;
			$scope.hiddenFooter = false;
			$scope.operative_eye = note.eye === "OD/OS" ? "BOTH" : note.eye;
			$scope.disableSelect = note.eye === "OD/OS" ? false : true;
			$scope.OperationNoteList = [];
			$scope.OperationNote = {
				operation_note_id: note.operation_note_id,
				patient_id: note.patient_id,
				op_note_od: { operative_eye: "NO" },
				op_note_os: { operative_eye: "NO" },
			};
			// $scope.successfulWorkup && $scope.successfulWorkup.hide();
		};

		$scope.saveSetting = function () {
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}

			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}

			$scope.isSubmitted = true;
			const valid = checkValid();
			if (!valid) return;

			if (!$scope.showWarning) {
				$scope.showWarning = true;
				return;
			}
			
			$scope.isSuccess = false;
			$scope.processingWorkup = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.processing-operation-note-workup.tpl.html",
				show: true,
				title: "PROCESSING...",
				keyboard: false,
				backdrop: "static",
			});
			$scope.displaySpinner = true;
			OperationAPI.saveDefaultSetting(cutRequestForSetting())
				.then(function (resp) {
					$scope.processingWorkup.hide();
					$scope.displaySpinner = false;
					$scope.submit();
				})
				.catch(function (error) {
					console.error(`save default settings ${JSON.stringify(error)}`);
					$scope.displaySpinner = false;
				});
		};

		$scope.addComment = function () {
			if (!$scope.vision.$valid) return;
			const body = {
				op_note_od_comment: $scope.OperationNote.op_note_od.comment,
				op_note_os_comment: $scope.OperationNote.op_note_os.comment,
			};
			OperationAPI.addNewComment($scope.OperationNote.patient_id, $scope.OperationNote.operation_note_id, body)
				.then(function (resp) {
					requestOperationNote();
					$scope.showCommentsOnly = false;
				})
				.catch(function (error) {
					console.error(`append comment ${JSON.stringify(error)}`);
				});
		};
		$scope.canSave = function () {
			//NOTE: remove "&& phaco&IOL", so taht all settings can be saved
			return $scope.OperationNote && $scope.OperationNote.surgeon_user_id === parseInt(Session.userId) && phacoAndIOL();
		};

		function requestWorkUp() {
			SurgeryAPI.getWorkUpDetails($scope.CP.patient.id).then(function () {
				$scope.PreOp = PreOp.data;
				// mergePreOp();
			});
		}

		function mergePreOp(eyes) {
			if ($scope.PreOp.surgical_plan_section && $scope.PreOp.surgical_plan_section.surgical_plan_intraocular) {
				const od = $scope.PreOp.surgical_plan_section.surgical_plan_intraocular.od;
				const os = $scope.PreOp.surgical_plan_section.surgical_plan_intraocular.os;

				if (od && eyes === 'OD') {
					if (!$scope.OperationNote.op_note_od.iol) {
						$scope.OperationNote.op_note_od.iol = {};
					}

					$scope.OperationNote.op_note_od.iol.lens_type = od.lens_type;
					$scope.OperationNote.op_note_od.iol.lens_model = od.lens_model;
					$scope.OperationNote.op_note_od.iol.lens_power_spherical = od.lens_power_spherical;
					$scope.OperationNote.op_note_od.iol.lens_power_cylinder = od.lens_power_cylinder;

				    $scope.OperationNote.op_note_od.iol.lens_axis = null; // od.intended_axis;
					// $scope.OperationNote.op_note_od.iol.target_refraction_cylinder = od.target_refraction_cylinder;
					// $scope.OperationNote.op_note_od.iol.target_refraction_axis = od.target_refraction_axis;
					// $scope.OperationNote.op_note_od.iol.target_refraction_sphere = od.target_refraction_sphere;
					
					if(['NON_TORIC_ADDON','TORIC_ADDON'].includes($scope.OperationNote.op_note_od.iol.lens_type)){
						$scope.OperationNote.op_note_od.iol.lens_placement = 'Sulcus';
					}
				}

				if (os && eyes === 'OS') {
					if (!$scope.OperationNote.op_note_os.iol) {
						$scope.OperationNote.op_note_os.iol = {};
					}
					$scope.OperationNote.op_note_os.iol.lens_type = os.lens_type;
					$scope.OperationNote.op_note_os.iol.lens_model = os.lens_model;
					$scope.OperationNote.op_note_os.iol.lens_power_spherical = os.lens_power_spherical;
					$scope.OperationNote.op_note_os.iol.lens_power_cylinder = os.lens_power_cylinder;

					$scope.OperationNote.op_note_os.iol.lens_axis = null; // os.intended_axis;
					// $scope.OperationNote.op_note_os.iol.target_refraction_cylinder = os.target_refraction_cylinder;
					// $scope.OperationNote.op_note_os.iol.target_refraction_axis = os.target_refraction_axis;
					// $scope.OperationNote.op_note_os.iol.target_refraction_sphere = os.target_refraction_sphere;

					if(['NON_TORIC_ADDON','TORIC_ADDON'].includes($scope.OperationNote.op_note_os.iol.lens_type)){
						$scope.OperationNote.op_note_os.iol.lens_placement = 'Sulcus';
					}
					// $scope.OperationNote.op_note_od.iol.lens_serial_number = os.lens_serial_number;
				}
			}
			if ($scope.PreOp.surgical_plan_section && $scope.PreOp.surgical_plan_section.surgical_plan_anaesthetic) {
				$scope.OperationNote.op_note_od.anaesthetic = $scope.PreOp.surgical_plan_section.surgical_plan_anaesthetic.anaesthetic;
				$scope.OperationNote.op_note_os.anaesthetic = $scope.PreOp.surgical_plan_section.surgical_plan_anaesthetic.anaesthetic;
			}
		
			if ($scope.PreOp.surgical_plan_section && $scope.PreOp.surgical_plan_section.surgical_plan_adjuvant) {
				const od = $scope.PreOp.surgical_plan_section.surgical_plan_adjuvant.od;
				const os = $scope.PreOp.surgical_plan_section.surgical_plan_adjuvant.os;

				if (od && eyes === 'OD') {
					if (!$scope.OperationNote.op_note_od.adjuvant_procedures) {
						$scope.OperationNote.op_note_od.adjuvant_procedures = {};
					}
					if (od.adjuvant_procedures === "NO") {
						// $scope.OperationNote.op_note_od.adjuvant_procedures.adjuvant_procedures = od.adjuvant_procedures;
					} else {
						// $scope.OperationNote.op_note_od.adjuvant_procedures.planned_anterior_vitrectomy =
						// 	od.adjuvant_procedures && od.adjuvant_procedures === "NO" ? null : od.planned_anterior_vitrectomy;
						// $scope.OperationNote.op_note_od.adjuvant_procedures.sulcus =
						// 	od.adjuvant_procedures && od.adjuvant_procedures === "NO" ? null : od.sulcus_sutured_lens;
						// $scope.OperationNote.op_note_od.adjuvant_procedures.capsular_tension_ring =
						// 	od.adjuvant_procedures && od.adjuvant_procedures === "NO" ? null : od.capsular_tension_ring;
						// $scope.OperationNote.op_note_od.adjuvant_procedures.intravitreal_injection =
						// 	od.adjuvant_procedures && od.adjuvant_procedures === "NO" ? null : od.intravitreal_injection;
						// $scope.OperationNote.op_note_od.adjuvant_procedures.migs =
						// 	od.adjuvant_procedures && od.adjuvant_procedures === "NO" ? null : od.migs_stent;
						$scope.OperationNote.op_note_od.adjuvant_procedures.other = od.other ? [od.other] : null;
					}
				}

				if (os && eyes === 'OS') {
					if (!$scope.OperationNote.op_note_os.adjuvant_procedures) {
						$scope.OperationNote.op_note_os.adjuvant_procedures = {};
					}
					if (os.adjuvant_procedures === "NO") {
						// $scope.OperationNote.op_note_os.adjuvant_procedures.adjuvant_procedures = os.adjuvant_procedures;
					} else {
						// $scope.OperationNote.op_note_os.adjuvant_procedures.planned_anterior_vitrectomy = (os.adjuvant_procedures && os.adjuvant_procedures === 'NO') ? null : os.planned_anterior_vitrectomy;
						// $scope.OperationNote.op_note_os.adjuvant_procedures.sulcus = (os.adjuvant_procedures && os.adjuvant_procedures === 'NO') ? null : os.sulcus_sutured_lens;
						// $scope.OperationNote.op_note_os.adjuvant_procedures.capsular_tension_ring = (os.adjuvant_procedures && os.adjuvant_procedures === 'NO') ? null : os.capsular_tension_ring;
						// $scope.OperationNote.op_note_os.adjuvant_procedures.intravitreal_injection = (os.adjuvant_procedures && os.adjuvant_procedures === 'NO') ? null : os.intravitreal_injection;
						// $scope.OperationNote.op_note_os.adjuvant_procedures.migs = (os.adjuvant_procedures && os.adjuvant_procedures === 'NO') ? null : os.migs_stent;
						$scope.OperationNote.op_note_os.adjuvant_procedures.other = os.other ? [os.other] : null;
					}
					
				}
			} 
		}

		function createNewForm() {
			let patient_id = $scope.CP.patient.id;
			if ($scope.OperationNoteList.length > 0) {
				patient_id = $scope.OperationNoteList[0].patient_id;
			}
			const completed_by_user_id = Session.userId;
			const practice_id = Session.practice.id;
			$scope.OperationNoteList = [];
			$scope.operative_eye = "";
			$scope.disableSelect = false;
			$scope.hiddenFooter = false;
			$scope.OperationNote = {
				operation_note_id: null,
				patient_id: patient_id,
				completed_by_user_id: completed_by_user_id,
				surgeon_user_id: null,
				assistant_user_id: null,
				practice_id: practice_id,
				status: null,
				date_created: null,
				date_updated: null,
				op_note_od: {},
				op_note_os: {},
				postop_medications: [],
				postop_care: null,
			};
			const currentSurgeon = $scope.ophthalmologists.find((t) => t.value == Session.userId);
			$scope.OperationNote.surgeon_user_id = currentSurgeon ? currentSurgeon.value : null;
			$scope.operationNoteHeader = "OPERATION NOTE";
			if (!$scope.PreOp) {
				requestWorkUp();
			} else {
				// mergePreOp();
			}

			// $scope.successfulWorkup.hide();
		};

		function requestOperationNote() {
			$scope.showWarning = false;
			$scope.displaySecondaryNHI = false;
			$scope.displaySecondaryInsurance = false;

			// OperationAPI.getOperationNotes($scope.CP.patient.id).then(function () {
			if (OperationNote.noteList.length === 0) {
				$scope.OperationNote = OperationNote.data;
				$scope.OperationNoteList = [];
				$scope.operationNoteHeader = "OPERATION NOTE";
				createNewForm();
				requestWorkUp();
			} else {
				$scope.OperationNoteList = [];
				createNewForm();
				/**  DONOT SHOW HISTORY
				$scope.OperationNoteList = OperationNote.noteList;
				$scope.operationNoteHeader = "SURGICAL HISTORY"; */
				return;
			}
			let flag = $scope.operative_eye;
			if ($scope.OperationNote.op_note_od !== null && $scope.OperationNote.op_note_od.operative_eye === "YES") {
				flag = "OD";
			}

			if ($scope.OperationNote.op_note_os !== null && $scope.OperationNote.op_note_os.operative_eye === "YES") {
				flag = flag + "OS";
			}
			$scope.operative_eye = flag === "ODOS" ? "BOTH" : flag;
			composeNoteDetails();
			// const currentSurgeon = $scope.ophthalmologists.find((t) => t.value === Session.userId);
			// $scope.OperationNote.surgeon_user_id = currentSurgeon ? currentSurgeon.value : null;
			// });
		}

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		function getFileName() {
			var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
			return (
				"CatTrax_operation_note_" +
				theTime +
				"_" +
				$scope.CP.patient.firstName +
				"_" +
				$scope.CP.patient.lastName +
				"_" +
				$scope.CP.patient.dateOfBirth +
				".pdf"
			);
		}

		function common(newValue) {
			if ($scope.vision && $scope.vision.$error.required) {
				let form = angular.element(document.querySelector("#operation-form"));
				$timeout(
					function () {
						if ($scope.isSubmitted) {
							form.submit();
						}
					},
					0,
					false
				);
			}
		}

		function phacoAndIOL() {
			if (OPNOTE_SAVE_ALL_SETTINGS_1020) {
				return (
					($scope.OperationNote.op_note_od && $scope.OperationNote.op_note_od.operative_eye === "YES") ||
					($scope.OperationNote.op_note_os && $scope.OperationNote.op_note_os.operative_eye === "YES")
				);
			}
			return (
				($scope.OperationNote.op_note_od && $scope.OperationNote.op_note_od.operative_eye === "YES" &&
					["PHACO_IOL", "OTHER"].includes($scope.OperationNote.op_note_od.procedure_type)) ||
				($scope.OperationNote.op_note_os && $scope.OperationNote.op_note_os.operative_eye === "YES" && ["PHACO_IOL", "OTHER"].includes($scope.OperationNote.op_note_os.procedure_type))
			);
		}

		function requestDefaultSetting() {
			if (
				$scope.OperationNote &&
				$scope.OperationNote.surgeon_user_id &&
				$scope.OperationNote.operation_note_id == null 
				//&&
				// phacoAndIOL() &&
				// $scope.remoteDefaultSetting == null
			) {
				OperationNote.setDefaultSetting(null);
				OperationAPI.getDefaultSetting($scope.OperationNote.surgeon_user_id,$scope.CP.patient.id).then(function () {
					$scope.remoteDefaultSetting = angular.copy(OperationNote.setting);
					if ($scope.remoteDefaultSetting && $scope.remoteDefaultSetting.op_note_settings) {
						$scope.isSubmitted = false;
						if($scope.remoteDefaultSetting.op_note_settings.operation_note_other_procedure_settings){
							$scope.operation_note_other_procedure_settings = angular.copy($scope.remoteDefaultSetting.op_note_settings.operation_note_other_procedure_settings);
						}
						// mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, 'OU');
					}
				});
				if (!$scope.OperationNote.postop_care) $scope.OperationNote.postop_care = {};
				$scope.OperationNote.postop_care.clinician = $scope.ophthalmologists.find(
					(t) => t.value === $scope.OperationNote.surgeon_user_id
				).name;
			}
		}

		function composeNoteDetails() {
			let str = "";
			if ($scope.OperationNote.op_note_od.procedure_type) {
				str =
					"right eye " +
					OPERATIONPROCEDURE.find((t) => t.id === $scope.OperationNote.op_note_od.procedure_type).name +
					" procedure";
			}
			if ($scope.OperationNote.op_note_os.procedure_type) {
				str =
					str +
					" left eye " +
					OPERATIONPROCEDURE.find((t) => t.id === $scope.OperationNote.op_note_os.procedure_type).name +
					" procedure";
			}
			$scope.noteDetails = str;
		}

		function checkValid() {
			let valid = $scope.vision.$valid;
			if (!valid) {
				const copyError = [];
				if ($scope.vision.$error.required) {
					$scope.vision.$error.required.forEach((t) => {
						if (-1 === t.$name.indexOf("eye_sub_form")) {
							copyError.push(t);
						}
					});
				}
				if (copyError.length === 0) {
					valid = true;
				}
			}

			if ($scope.operative_eye === "OD" && $scope.OperationNote.op_note_od.procedure_type !== 'OTHER') {
				valid =
					valid &&
					$scope.OperationNote.op_note_od.incisions &&
					$scope.OperationNote.op_note_od.incisions.length > 0;
			} else if ($scope.operative_eye === "OS" && $scope.OperationNote.op_note_os.procedure_type !== 'OTHER') {
				valid =
					valid &&
					$scope.OperationNote.op_note_os.incisions &&
					$scope.OperationNote.op_note_os.incisions.length > 0;
			} else if($scope.operative_eye === "OU") {
				if ($scope.OperationNote.op_note_od.procedure_type !== 'OTHER') {
					valid =
						valid &&
						$scope.OperationNote.op_note_od.incisions &&
						$scope.OperationNote.op_note_od.incisions.length > 0
				}
				if ($scope.OperationNote.op_note_os.procedure_type !== 'OTHER') {
					valid =
						valid &&
						$scope.OperationNote.op_note_os.incisions &&
						$scope.OperationNote.op_note_os.incisions.length > 0;
				}
				
			}
			//valid = valid && $scope.OperationNote.postop_medications.length > 0;
			return valid;
		}

		$scope.hideSecondaryNhiComponent = function () {
			$scope.displaySecondaryNHI = false;
		};

		$scope.hideSecondaryInsuranceComponent = function () {
			$scope.displaySecondaryInsurance = false;
		};

		$scope.procedureChangedOD = function () {
			if($scope.OperationNote.op_note_od.procedure_type === 'OTHER'){
				// $scope.OperationNote.postop_medications = [];
			}
			if ($scope.remoteDefaultSetting && $scope.remoteDefaultSetting.op_note_settings && $scope.remoteDefaultSetting.op_note_settings.op_note_od_settings &&
				($scope.remoteDefaultSetting.op_note_settings.op_note_od_settings.procedure_type === $scope.OperationNote.op_note_od.procedure_type ||
					$scope.remoteDefaultSetting.op_note_settings.op_note_od_settings_list &&
					$scope.remoteDefaultSetting.op_note_settings.op_note_od_settings_list.some(setting => setting.procedure_type === $scope.OperationNote.op_note_od.procedure_type))
				) {
				mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, 'OD');
			} else {
				const copy = angular.copy($scope.OperationNote);
				copy.op_note_od.ovd = [];
				copy.op_note_od.ccc = {};
				copy.op_note_od.phaco = {};
				copy.op_note_od.iol = {};
				copy.op_note_od.anaesthetic = null;
				copy.op_note_od.incisions = null;
				copy.op_note_od.intraoperative_medications = [];
				$scope.OperationNote = copy;
				mergePreOp('OD');

			}
		}

		$scope.procedureChangedOS = function () {
			if($scope.OperationNote.op_note_os.procedure_type === 'OTHER'){
				// $scope.OperationNote.postop_medications = [];
			}
			if ($scope.remoteDefaultSetting && $scope.remoteDefaultSetting.op_note_settings && $scope.remoteDefaultSetting.op_note_settings.op_note_os_settings &&
				($scope.remoteDefaultSetting.op_note_settings.op_note_os_settings.procedure_type === $scope.OperationNote.op_note_os.procedure_type ||
					$scope.remoteDefaultSetting.op_note_settings.op_note_os_settings_list &&
					$scope.remoteDefaultSetting.op_note_settings.op_note_os_settings_list.some(setting => setting.procedure_type === $scope.OperationNote.op_note_os.procedure_type))
			) {
				mergeDefaultSetting($scope.remoteDefaultSetting.op_note_settings, 'OS');
			} else {
				const copy = angular.copy($scope.OperationNote);
				copy.op_note_os.ovd = [];
				copy.op_note_os.ccc = {};
				copy.op_note_os.phaco = {};
				copy.op_note_os.iol = {};
				copy.op_note_os.anaesthetic = null;
				copy.op_note_os.incisions = null;
				copy.op_note_os.intraoperative_medications = [];
				$scope.OperationNote = copy;
				mergePreOp('OS');
			}
		}

		$scope.setDefaultMedications = function (value, eyes) {
			const copy = angular.copy($scope.OperationNote);
			if(!value){
				return;
			}
			value.forEach((t) => {
				if (t.route !== "ORAL") {
					t.route = eyes;
				}
			});
			copy.postop_medications = mergePostOpMedications(copy.postop_medications, value, eyes);
			$scope.OperationNote = copy;
		}

		function mergePostOpMedications(existingValue, newValue, eyes) {
			//Delete medications from existingValue (based on eyes)
			const temp = [];
			existingValue.forEach(t => {
				if (t.route === eyes && t.route !== 'ORAL') {
					// do nothing
				} else if (t.route === 'BOTH') {
					temp.push({ ...t, route: eyes === 'OD' ? 'OS' : 'OD' });
				} else {
					temp.push(t);
				}
			});

			const copyExistingValue = angular.copy(temp);			
			const appendArray = [];
			newValue.forEach(t => {
				const target = copyExistingValue.find(m => m.medication === t.medication &&
					m.dose === t.dose &&
					m.frequency === t.frequency &&
					m.duration === t.duration);
				if (target && target.route !== "ORAL") {
					if (target.route !== t.route) {
						target.route = 'BOTH';
					}
				} else {
					appendArray.push(t);
				}
			});
			appendArray.forEach(t => copyExistingValue.push(t));
			return copyExistingValue;
		}

		function mergeDefaultSetting(op_note_settings, eyes) {
			const copy = angular.copy($scope.OperationNote);


			// EDIT

			//This fixes procedure_type being undefined during race condition.
			$scope.OperationNote.op_note_od.procedure_type ??= "PHACO_IOL"


			let opNoteOdSettings = op_note_settings.op_note_od_settings;

			if(OPNOTE_SAVE_ALL_SETTINGS_1020) {
				let listSettings = $scope.remoteDefaultSetting.op_note_settings.op_note_od_settings_list.find((setting) => 
					setting.procedure_type === $scope.OperationNote.op_note_od.procedure_type
				);
				opNoteOdSettings = listSettings?? opNoteOdSettings;
			}
			if (opNoteOdSettings && eyes === 'OD') {

				//Check if New settings exist, then change op_note_settings to point to new settings
				if (opNoteOdSettings.procedure_type) {
					copy.op_note_od.procedure_type = opNoteOdSettings.procedure_type;
				} 
				if (opNoteOdSettings.anaesthetic) {
					copy.op_note_od.anaesthetic = opNoteOdSettings.anaesthetic;
				}
				if (opNoteOdSettings.incisions) {
					copy.op_note_od.incisions = opNoteOdSettings.incisions;
				}

				if (opNoteOdSettings.ovd) {
					copy.op_note_od.ovd = opNoteOdSettings.ovd;
				}

				if (opNoteOdSettings.ccc) {
					if (!copy.op_note_od.ccc) copy.op_note_od.ccc = {};
					copy.op_note_od.ccc.dilated_pupil_size =
						opNoteOdSettings.ccc.dilated_pupil_size || copy.op_note_od.ccc.dilated_pupil_size;
					copy.op_note_od.ccc.pupil_expander =
						opNoteOdSettings.ccc.pupil_expander || copy.op_note_od.ccc.pupil_expander;
					copy.op_note_od.ccc.technique_type =
						opNoteOdSettings.ccc.technique_type || copy.op_note_od.ccc.technique_type;
					copy.op_note_od.ccc.hydrodissection_type =
						opNoteOdSettings.ccc.hydrodissection_type || copy.op_note_od.ccc.hydrodissection_type;
				}
				if (opNoteOdSettings.phaco) {
					if (!copy.op_note_od.phaco) copy.op_note_od.phaco = {};
					copy.op_note_od.phaco.machine = opNoteOdSettings.phaco.machine || copy.op_note_od.phaco.machine;
					copy.op_note_od.phaco.technique = opNoteOdSettings.phaco.technique || copy.op_note_od.phaco.technique;
					copy.op_note_od.phaco.irrigation =
						opNoteOdSettings.phaco.irrigation || copy.op_note_od.phaco.irrigation;
					// copy.op_note_od.phaco.ultrasound_energy =
					// 	opNoteOdSettings.phaco.ultrasound_energy || copy.op_note_od.phaco.ultrasound_energy;
				}

				if (opNoteOdSettings.iol) {
					if (copy.op_note_od.iol && copy.op_note_od.iol.lens_type) {
						//use pre-op value
						copy.op_note_od.iol.lens_placement =
							opNoteOdSettings.iol.lens_placement || copy.op_note_od.iol.lens_placement;
					} else {
						if (!copy.op_note_od.iol) copy.op_note_od.iol = {};
						copy.op_note_od.iol.lens_type = opNoteOdSettings.iol.lens_type || copy.op_note_od.iol.lens_type;
						copy.op_note_od.iol.lens_model = opNoteOdSettings.iol.lens_model || copy.op_note_od.iol.lens_model;
						copy.op_note_od.iol.lens_power_spherical = opNoteOdSettings.iol.lens_power_spherical || copy.op_note_od.iol.lens_power_spherical;
						copy.op_note_od.iol.lens_placement =
							opNoteOdSettings.iol.lens_placement || copy.op_note_od.iol.lens_placement;
						copy.op_note_od.iol.lens_power_cylinder = opNoteOdSettings.iol.lens_power_cylinder || copy.op_note_od.iol.lens_power_cylinder;
						copy.op_note_od.iol.lens_axis = opNoteOdSettings.iol.lens_axis || copy.op_note_od.iol.lens_axis;
					}

				}
				copy.op_note_od.intraoperative_medications =
					opNoteOdSettings.intraoperative_medications || copy.op_note_od.intraoperative_medications;

				copy.op_note_od.instructions_for_patient = opNoteOdSettings.instructions_for_patient || copy.op_note_od.instructions_for_patient;
			}

			let opNoteOsSettings = op_note_settings.op_note_os_settings;
			if(OPNOTE_SAVE_ALL_SETTINGS_1020) {
				listSettings = $scope.remoteDefaultSetting.op_note_settings.op_note_os_settings_list.find((setting) => 
					setting.procedure_type === $scope.OperationNote.op_note_os.procedure_type
				);
				opNoteOsSettings = listSettings?? opNoteOsSettings;
			}
			if (opNoteOsSettings && eyes === 'OS') {
				if (opNoteOsSettings.procedure_type) {
					copy.op_note_os.procedure_type = opNoteOsSettings.procedure_type;
				}
				if (opNoteOsSettings.anaesthetic) {
					copy.op_note_os.anaesthetic = opNoteOsSettings.anaesthetic;
				}
				if (opNoteOsSettings.incisions) {
					copy.op_note_os.incisions = opNoteOsSettings.incisions;
				}

				if (opNoteOsSettings.ovd) {
					copy.op_note_os.ovd = opNoteOsSettings.ovd;
				}
				if (opNoteOsSettings.ccc) {
					if (!copy.op_note_os.ccc) copy.op_note_os.ccc = {};
					copy.op_note_os.ccc.dilated_pupil_size =
						opNoteOsSettings.ccc.dilated_pupil_size || copy.op_note_os.ccc.dilated_pupil_size;
					copy.op_note_os.ccc.pupil_expander =
						opNoteOsSettings.ccc.pupil_expander || copy.op_note_os.ccc.pupil_expander;
					copy.op_note_os.ccc.technique_type =
						opNoteOsSettings.ccc.technique_type || copy.op_note_os.ccc.technique_type;
					copy.op_note_os.ccc.hydrodissection_type =
						opNoteOsSettings.ccc.hydrodissection_type || copy.op_note_os.ccc.hydrodissection_type;
				}

				if (opNoteOsSettings.phaco) {
					if (!copy.op_note_os.phaco) copy.op_note_os.phaco = {};
					copy.op_note_os.phaco.machine = opNoteOsSettings.phaco.machine || copy.op_note_os.phaco.machine;
					copy.op_note_os.phaco.technique = opNoteOsSettings.phaco.technique || copy.op_note_os.phaco.technique;
					copy.op_note_os.phaco.irrigation =
						opNoteOsSettings.phaco.irrigation || copy.op_note_os.phaco.irrigation;
					// copy.op_note_os.phaco.ultrasound_energy =
					// 	opNoteOsSettings.phaco.ultrasound_energy || copy.op_note_os.phaco.ultrasound_energy;
				}

				if (opNoteOsSettings.iol) {
					if (copy.op_note_os.iol && copy.op_note_os.iol.lens_type) {
						//use pre-op value
						copy.op_note_os.iol.lens_placement =
							opNoteOsSettings.iol.lens_placement || copy.op_note_os.iol.lens_placement;
					} else {
						if (!copy.op_note_os.iol) copy.op_note_os.iol = {};
						copy.op_note_os.iol.lens_type = opNoteOsSettings.iol.lens_type || copy.op_note_os.iol.lens_type;
						copy.op_note_os.iol.lens_model = opNoteOsSettings.iol.lens_model || copy.op_note_os.iol.lens_model;
						copy.op_note_os.iol.lens_power_spherical = opNoteOsSettings.iol.lens_power_spherical || copy.op_note_os.iol.lens_power_spherical;
						copy.op_note_os.iol.lens_placement =
							opNoteOsSettings.iol.lens_placement || copy.op_note_os.iol.lens_placement;
						copy.op_note_os.iol.lens_power_cylinder = opNoteOsSettings.iol.lens_power_cylinder || copy.op_note_os.iol.lens_power_cylinder;
						copy.op_note_os.iol.lens_axis = opNoteOsSettings.iol.lens_axis || copy.op_note_os.iol.lens_axis;
					}
				}
				copy.op_note_os.intraoperative_medications =
					opNoteOsSettings.intraoperative_medications || copy.op_note_os.intraoperative_medications;

				copy.op_note_os.instructions_for_patient = opNoteOsSettings.instructions_for_patient || copy.op_note_os.instructions_for_patient;
			}

			if (op_note_settings.postop_care) {
				if (!copy.postop_care) copy.postop_care = {};
				copy.postop_care.duration = op_note_settings.postop_care.duration || copy.postop_care.duration;
				copy.postop_care.other_date = op_note_settings.postop_care.other_date || copy.postop_care.other_date;
				copy.postop_care.clinician = op_note_settings.postop_care.clinician || copy.postop_care.clinician;
			}

			if (op_note_settings.postop_medications) {
				const temp = angular.copy(op_note_settings.postop_medications);
				temp.forEach((t) => {
					if (t.route !== "ORAL") {
						t.route = eyes;
					}
				});
				copy.postop_medications = mergePostOpMedications(copy.postop_medications, temp, eyes);
				// copy.postop_medications = [];
				// op_note_settings.postop_medications.forEach((t) => {
				// 	if (t.route !== "ORAL") {
				// 		t.route = $scope.operative_eye;
				// 	}
				// 	copy.postop_medications.push(t);
				// });
			}

			// if(op_note_settings.operation_note_other_procedure_settings){
            //     $scope.operation_note_other_procedure_settings = angular.copy(op_note_settings.operation_note_other_procedure_settings);
			// }

			$scope.OperationNote = copy;
			mergePreOp(eyes);
		}

		$scope.opNoteOption = {};

		$scope.onAutomationTaskChange = function(event) {
			console.log('xxxx:'+JSON.stringify(event))
			if(event.type === 'operation_note_automation_settings'){
				$scope.opNoteOption.addAutoTaskOpNote = event.isAutomationTaskEnabled;
			}else if(event.type === 'histology_automation_settings'){
				$scope.opNoteOption.addAutoTaskHistology = event.isAutomationTaskEnabled;
			}
		}

	},
]);