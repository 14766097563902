app.controller("settingTabsController", [
	"$scope",
	"AppointmentAPI",
	"PracticeService",
    "TasksAPI",
	"CALENDAR_AVAILABILITY_991",
	function ($scope, AppointmentAPI, PracticeService,TasksAPI,CALENDAR_AVAILABILITY_991) {
		$scope.API = AppointmentAPI;
		$scope.PracticeAPI = PracticeService;
        $scope.TasksAPI = TasksAPI;
		$scope.calendarAvailabilityEnabled = CALENDAR_AVAILABILITY_991;
	},
]);