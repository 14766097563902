app.component("preOpNhi", {
	templateUrl: "app/src/js/components/preOp/preOpNhi/preOpNhi.html",
	controller: "preOpNhiController",
	bindings: {
		hide: "&",
		eyeTitle: "@",
		hideSecondaryNhiComponent: "&",
	},
});

app.controller("preOpNhiController", [
	"$scope",
	"PreOp",
	"NHI",
	"CurrentPatient",
	"PatientAPI",
	"$timeout",
	"NHISearchPatientsAPI",
	"Session",
	"NHI_VERSION_V2",
	function ($scope, PreOp, NHI, CurrentPatient, PatientAPI, $timeout, NHISearchPatientsAPI, Session,NHI_VERSION_V2) {
		this.$onInit = function () {
			$scope.preop = PreOp;
			$scope.NHI_VERSION_V2 = NHI_VERSION_V2;
			$scope.PA = PatientAPI;
			this.PA = PatientAPI;
			$scope.CP = CurrentPatient;
			$scope.NHIAPI = NHISearchPatientsAPI;
			$scope.fhirNhiFeature = Session.practice.practiceFeatures.fhirNhiFeature;
			$scope.formData = {
				nhi: "",
			};
		};
		$scope.result = []
		$scope.isNHIFeatureEnabled = false;
		
		if (
			Session.practice.practiceFeatures &&
			(Session.practice.practiceFeatures.nhiFeature === true || Session.practice.practiceFeatures.fhirNhiFeature === true)
		) {
			$scope.isNHIFeatureEnabled = true;
		}

		$timeout(function () {
			// focus on input when component opens
			document.querySelector("#NHI").focus();
			// $scope.fetchNHI('');
		}, 200);

		$scope.fetchNHI = function(value, theForm) {
			if (value === "") {
				$scope.badNHI = false;
				theForm.NHI.$setValidity("badNHI", true);
				return [];
			}
			if (NHI.validate(value) === true) {
				$scope.badNHI = false;
				theForm.NHI.$setValidity("badNHI", true);
				$scope.loadingNHI = true;
				return new Promise((resolve, reject) => {
					searchNHIPatients(value,resolve,reject)
				  })
			} else {
				$scope.badNHI = true;
				theForm.NHI.$setValidity("badNHI", false);
				return [];
			}
		  };
		
		function searchAllNHIPatients(nhi) {
			return $scope.NHI_VERSION_V2 ? $scope.NHIAPI.searchAllNHIPatientsV2(nhi) : $scope.NHIAPI.searchAllNHIPatients(nhi);
		}

		function getNHICode(array) {
			if(!array || array.length === 0){
				return ''
			}
			const officialObj = array.find(t=>t.type === 'OFFICIAL');
			return (officialObj && officialObj.nhi) ? officialObj.nhi : array[0].nhi;
		}


		function searchNHIPatients(nhi, resolve, reject) {
			$scope.result = [];
			searchAllNHIPatients(nhi)
				.then(
					function (resp) {
						if (resp.data) {

							if($scope.NHI_VERSION_V2){
								if(Array.isArray(resp.data)){
									$scope.result=[resp.data.map(t=>({...t,middleNameCol: t.names.find(t=>t.preferred).givenNameList[1],
										familyNameCol: t.names.find(t=>t.preferred).familyName,
										firstNameCol: t.names.find(t=>t.preferred).givenNameList[0],
										codedEthnicities:  angular.copy(t.ethnicities),
										nhi: getNHICode(t.nhi)}))]
								}else{
									const data = resp.data;
									$scope.result = [
										{
											...data,
											middleNameCol: data.names.find((t) => t.preferred).givenNameList[1],
											familyNameCol: data.names.find((t) => t.preferred).familyName,
											firstNameCol: data.names.find((t) => t.preferred).givenNameList[0],
											codedEthnicities:  angular.copy(data.ethnicities),
											nhi: getNHICode(data.nhi),
										},
									];
								}
								
							}else{
								$scope.result=[resp.data]
							}

							
							resolve($scope.result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
						reject(err)
					}
				).then(function () {
					$scope.loadingNHI = false;
				})
		}


		function composeDOB(dateOfBirth) {
			let DD,MM,YYYY;
			if($scope.fhirNhiFeature){
				DD = parseInt(dateOfBirth[0]);
				MM = parseInt(dateOfBirth[1]);
				YYYY = parseInt(dateOfBirth[2]);
			}else{
				DD = parseInt(dateOfBirth[2]);
				MM = parseInt(dateOfBirth[1]);
				YYYY = parseInt(dateOfBirth[0]);
			}
			return `${YYYY}-${MM}-${DD}`;
		}

		function getNZCitizenState(nzCitizen) {
			if(nzCitizen === 'YES'){
			   return true;
			}else if(nzCitizen === 'NO'){
			   return false;
			}
			return null;
		 }

		function presetPatient(patient) {
			const DD = patient.dateOfBirth.substr(8, 2);
			const MM = patient.dateOfBirth.substr(5, 2);
			const YYYY = patient.dateOfBirth.substr(0, 4);
			$scope.CP.patient.lastName = patient.names[0].lastName;
			$scope.CP.patient.dateOfBirth = YYYY + "-" + MM + "-" + DD;
			$scope.CP.patient.firstName = patient.names[0].firstName;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			$scope.CP.patient.isResident = patient.isResident ? $scope.CP.patient.isResident : false;
			if (patient.contacts && patient.contacts.length > 0) {
				if (!patient.contacts[0].confidentialInformation) {
					$scope.CP.patient.phoneNumber = patient.contacts[0].phoneNumber
				}
			}
			$scope.CP.isPresetNewPatient = true;
		}

		function presetPatientV2(patient) {
			const dateOfBirth = patient.dob.split('-');
			const DD = parseInt(dateOfBirth[0]);
			const MM = parseInt(dateOfBirth[1]);
			const YYYY = parseInt(dateOfBirth[2]);
			
			$scope.CP.patient.dateOfBirth = composeDOB(dateOfBirth);
			$scope.CP.patient.lastName = patient.familyNameCol;
			$scope.CP.patient.firstName = patient.firstNameCol;
			$scope.CP.patient.nhi = patient.nhi;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			$scope.CP.patient.isResident = getNZCitizenState(patient.nzCitizen);
			$scope.CP.isPresetNewPatient = true;
		}


		$scope.saveSimpleNHI = function (nhi) {
			$scope.CP.patient.nhi = nhi;
			if(!$scope.CP.patient.id) {
				$scope.$ctrl.hide();
				return;
			} 
			$scope.PA.isProcessing = true;
			$scope.PA.update($scope.CP.patient)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.$ctrl.hide();
					$scope.$ctrl.hideSecondaryNhiComponent();
				})
				.catch(function (err) {
					$scope.PA.isProcessing = false;
					console.log(err);
				});
		};


		$scope.saveNHI = function (nhi) {
			if(!$scope.isNHIFeatureEnabled){
				$scope.saveSimpleNHI($scope.formData.nhi);
				return;
			}
			if($scope.result[0] && $scope.result[0].nhi === nhi){
				const backupPatient = angular.copy($scope.CP.patient);
				$scope.CP.patient.nhi = nhi;

				if($scope.NHI_VERSION_V2){
					presetPatientV2($scope.result[0])
				}else{
					presetPatient($scope.result[0]);
				}
   
				if(!$scope.CP.patient.id) {
					$scope.$ctrl.hide();
					return;
				} 
		
				$scope.PA.isProcessing = true;
				$scope.PA.update($scope.CP.patient)
					.then(function (resp) {
						$scope.CP.setPatient(resp.data);
					}, function (err) {
						$scope.CP.setPatient(backupPatient);
						$scope.PA.isProcessing = false;
						console.log(err);
					}
					)
					.then(function () {
						$scope.PA.isProcessing = false;
						$scope.$ctrl.hide();
						$scope.$ctrl.hideSecondaryNhiComponent();
					});
			}
		};
	},
]);
