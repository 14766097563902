app.component("preOpHeaderV2", {
	templateUrl: "app/src/js/components/preOp/preOpHeaderV2/preOpHeader.html",
	controller: "preOpHeaderV2Controller",
	bindings: {
		onEditClick: '&',
		showButton: '<',
		reload: '&',
	},
});

app.controller("preOpHeaderV2Controller", [
	"$scope",
	"$location",
	"CurrentPatient",
	"MEDICAL_INSURANCE",
	"PatientAPI",
	"Session",
	"ReferralsAPI",
	"CurrentReferral",
	"$window",
	"$state",
	"AllergyAPI",
	"ShareData",
	"BillingAPI",
	"$modal",
	"$mdDialog",
	"$filter",
	"AppointmentAPI",
	"$stateParams",
	"DYMO_PRINT_LABEL_947",
	function ($scope, $location, CurrentPatient, MEDICAL_INSURANCE, PatientAPI, Session, ReferralsAPI, CurrentReferral, $window, $state,AllergyAPI,ShareData,BillingAPI,$modal,$mdDialog,$filter,AppointmentAPI,$stateParams,DYMO_PRINT_LABEL_947) {
		// $scope.showButton = false;
		$scope.PA = PatientAPI;
		$scope.CP = CurrentPatient;
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.AllergyAPI = AllergyAPI;
		$scope.userIsPracticeAdmin = false;
		$scope.userIsTechnician = false;
		$scope.SD = ShareData;
		$scope.BillingAPI = BillingAPI;
		$scope.AppointmentAPI = AppointmentAPI;
		$scope.dymoEnabled = DYMO_PRINT_LABEL_947;
		

		$scope.showConsultTimer = true; // TODO 

		$scope.$watch("SD.allergies", function (newValue, oldValue) {
			if (newValue && newValue.patientId === $scope.CP.patient.id) {
				if(!newValue.reload){
					$scope.allergyList = newValue.allergyList.filter(t=>t.status === 'ACTIVE');
					const count = $scope.allergyList.length;
					if(count === 0){
						return $scope.allergyText = 'NKDA';
					}
					$scope.allergyText = count + (count > 1 ?' Allergies' : ' Allergy');
				}else{
					$scope.SD.clearAllergiesReload($scope.CP.patient.id);
					fetchPatientAllergy();
				}
				
			} 
		}, true);
		
		this.$onInit = function () {
			$scope.xeroEnabled = Session.practice.practiceFeatures.billingFeature === true;
			if($location.path().indexOf("operationNote") > -1){
				$scope.headerText = "OPERATION NOTE";
			}else if($location.path().indexOf("refer/search") > -1){
				$scope.headerText = "PATIENT DASHBOARD";
				// $scope.showButton = true;
			}else if($location.path().indexOf("clinicalNote") > -1){
				$scope.headerText = "VIRTUAL CONSULT";
			}else if($location.path().indexOf("injectionNote") > -1){
				$scope.headerText = "INJECTION NOTE";
			}else if($location.path().indexOf("pre-op") > -1){
				$scope.headerText = "WORKUP FOR SURGERY";
			}else if($location.path().indexOf("surgeryAgreement") > -1){
				$scope.headerText = "SURGERY AGREEMENT";
			}else if($location.path().indexOf("medicalCertificate") > -1 && $stateParams.isReturnToWork){
				$scope.headerText = "RETURN TO WORK CERTIFICATE";
			}else if($location.path().indexOf("medicalCertificate") > -1){
				$scope.headerText = "MEDICAL CERTIFICATE";
			}else {
				$scope.headerText = "CLINICAL EXAMINATION";
			}
			fetchPatientAllergy();
			fetchAppointmentsForPatient();
			$scope.patientContactList = [];
            $scope.contact_terms = {value: ''};
		};

		if (Session.user.roles.length === 1 && Session.user.roles[0] === "PRACTICE_ADMIN") {
			$scope.userIsPracticeAdmin = true;
		} else if (Session.user.roles.length === 1 && Session.user.roles[0] === "TECHNICIAN") {
			$scope.userIsTechnician = true;
		}

		$scope.popoverComing = {
			content:
				"Coming Soon",
		};
		

		$scope.getInsuranceName = function (provider) {
			const item = MEDICAL_INSURANCE.find(t => t.value === provider);
			return item ? item.name : 'Add insurance';
		}

		$scope.trigger = function (){
			$scope.$ctrl.onEditClick();
		}

		$scope.backDashboard = function(){
			if($scope.headerText === 'PATIENT DASHBOARD'){
				return $scope.$ctrl.reload();
			}
			$window.history.back();
		}

		$scope.isFavouritePatient = function () {
			return Session.user.features.favoritePatientsFeature.some(t => t.patientId === $scope.CP.patient.id);
		}

		$scope.addOrRemovePatient = function (isAdd){
			if(isAdd){
				$scope.PA.addToFavourite($scope.CP.patient.id)
				.then(
					function (resp) {
						if (resp.status === 202) {
							const added = {
								patientId: $scope.CP.patient.id,
								patientName: $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName
							};
							Session.user.features.favoritePatientsFeature.push(added);
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
			}else{
				$scope.PA.removeFavourite($scope.CP.patient.id)
				.then(
					function (resp) {
						if (resp.status === 202) {
							Session.user.features.favoritePatientsFeature =
								Session.user.features.favoritePatientsFeature.filter(t => t.patientId !== $scope.CP.patient.id);
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
			}
		}

		$scope.startReferral = function () {
			const isV2 = true; // disable other referral feature;
			if(isV2){
				$state.go("referral");
				return;
			}
			$scope.PA.isProcessing = true;
			$scope.RA.startReferral().then(
				function (resp) {
					$scope.CR.setReferral(resp.data);
					// $scope.patientDetailsModal.hide();
					$scope.PA.isProcessing = false;
					$state.go("examination");
				},
				function (err) {
					console.log(err);
					$scope.PA.isProcessing = false;
				}
			);
		};

		$scope.allergyText = '';
		$scope.allergyList = [];

		function fetchPatientAllergy() {
			if ($scope.CP.patient && $scope.CP.patient.id) {
				$scope.AllergyAPI.getNKDA($scope.CP.patient.id).then(
					function (resp) {
						if (!resp.data.no_known_drug_allergies) {
							$scope.AllergyAPI.getAllergy($scope.CP.patient.id).then(
								function (resp) {
									if (resp.data && resp.data.length > 0) {
										$scope.allergyList = resp.data.filter((t) => t.status === "ACTIVE");
										const count = $scope.allergyList.length;
										if (count === 0) {
											return ($scope.allergyText = "NKDA");
										}
										$scope.allergyText = count + (count > 1 ? " Allergies" : " Allergy");
									} else {
										$scope.allergyText = "NKDA";
									}
									$scope.SD.setAllergies($scope.$ctrl.patientId, resp.data);
								},
								function (err) {
									console.log(`${JSON.stringify(err)}`);
								}
							);
						}
					},
					function (err) {}
				);
			}
		}

		function searchInAdvance () {
			$scope.BillingAPI.isProcessing = true;
			$scope.isRegisterDisabled = false;
			const dob = $filter("date")(new Date($scope.CP.patient.dateOfBirth), 'dd/MM/yyyy');
			const nhi = $scope.CP.patient.nhi ? `, NHI: ${$scope.CP.patient.nhi}` : '';
			const term = `${$scope.CP.patient.firstName} ${$scope.CP.patient.lastName} (DOB: ${dob}${nhi})`;
            $scope.BillingAPI.getContact($scope.CP.patient.id,term).then(function(resp){
                if(resp.data.length > 0){
					//patient already registered, disable 'regoster' button
					$scope.isRegisterDisabled = true;
					$scope.patientContactList = resp.data;
				}
            },function(error){
                console.error(error);
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
				// fetchContact($scope.CP.patient.id,'', true);
            })
		}

		$scope.connectPatient = function () {
            angular.element(document.querySelector("#headerTitle")).trigger("click");
			fetchContact($scope.CP.patient.id,'', true);
		}

		$scope.unlinkContact = function () {
			if(!$scope.linkedContact){
				return;
			}
			$scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.unlinkContact($scope.CP.patient.id,$scope.linkedContact.contact_id).then(function(resp){
			    if($scope.contactsModal){
					$scope.contactsModal.hide();
				}
            },function(error){
                console.error(error);
				showErrorDialog();
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
		}

		function displayContactsModal () {
			// $scope.patientContactList = [];
            $scope.contact_terms.value = '';
			const title = $scope.patientContactList.length > 0 ? 'Your patient in Xero' : 'Select your patient in Xero';
			$scope.contactsModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.billing-contacts-list.tpl.html",
				show: true,
				backdrop: "static",
				title,
				keyboard: false,
			});
		}

		$scope.billingItems = [];
		$scope.tempForm = {item: null, unit: 1, discount: 0, unit_amount: 0};
		$scope.clinic_date = {value:null};
		$scope.due_date = {value:null};
		$scope.addedItems = [];
		$scope.billingContactId = null;
        $scope.createInvoice = function (contactId) {
			$scope.billingContactId = contactId;
			$scope.today = $filter("date")(new Date(), "dd-MMMM-yyyy");
			$scope.clinic_date.value = $scope.today;
			let due_date = new Date();
            due_date.setDate(due_date.getDate() + 14);
			$scope.due_date.value = $filter("date")(due_date, "dd-MMMM-yyyy");
			angular.element(document.querySelector("#headerTitle")).trigger("click");
			$scope.addedItems = [];
			$scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.fetchBillingItems().then(function(resp){
			   $scope.billingItems = angular.copy(resp.data);
			   $scope.selectedBillingItems = [];
			   $scope.createInvoiceModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.create-invoice.tpl.html",
				show: true,
				backdrop: "static",
				title: 'CREATE INVOICE IN XERO',
				keyboard: false,
			})
            },function(error){
                console.error(error);
				showErrorDialog();
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
		}

		$scope.filterByItem = function (typedValue) {
			return $scope.billingItems.filter(
				(t) =>
					t.name.toLowerCase().indexOf(typedValue.toLowerCase()) !== -1 ||
					t.code.toLowerCase().indexOf(typedValue.toLowerCase()) !== -1
			);
		};

		$scope.addItem = function() {
			$scope.addedItems.push(angular.copy($scope.tempForm));
			$scope.tempForm = {item: null, unit: 1, discount: 0, unit_amount: 0};
		}

		$scope.onItemChange = function(item, model, label, event) {
			if(item){
				$scope.tempForm.unit_amount = item.unit_price;
			}
        }

		// function savePatientEmail (recipient_email) {
		// 	if(!$scope.CP.patient.email && recipient_email){
		// 		$scope.CP.patient.email = recipient_email;
		// 		$scope.PA.update($scope.CP.patient)
		// 			.then(function (resp) {
		// 				$scope.CP.setPatient(resp.data);
		// 			})
		// 			.then(function () {
		// 				$scope.PA.isProcessing = false;
		// 			})
		// 			.catch(function (err) {
		// 				$scope.PA.isProcessing = false;
		// 				console.log(err);
		// 			});
		// 	}
		// }
		
		$scope.generateInvoice = function(){
			if(!$scope.billingContactId){
				return;
			}
			const body = {
				contact: {contact_id: $scope.billingContactId},
				reference: `${$scope.CP.patient.firstName} ${$scope.CP.patient.lastName}`,
				date: $filter("date")(new Date($scope.clinic_date.value), "yyyy-MM-dd"),
				due_date: $filter("date")(new Date($scope.due_date.value), "yyyy-MM-dd"),
				line_items: $scope.addedItems.map((t) => ({
					item_code: t.item.code,
					description: t.item.description,
					quantity: t.unit,
					account_code: t.item.account_code,
					tax_type: t.item.tax_type,
					unit_amount: t.unit_amount,
					discount_rate: t.discount,
				})),
			};

			$scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.createInvoice($scope.CP.patient.id, body).then(function(resp){
				// if($scope.CP.patient.appointment && resp.data.invoice_id){
				// 	$scope.CP.patient.appointment.invoice_id = resp.data.invoice_id;
				// 	$scope.AppointmentAPI.updateAppointment(
				// 		$scope.CP.patient.id,
				// 		$scope.CP.patient.appointment.patient_appointment_id,
				// 		$scope.CP.patient.appointment
				// 	)
				// 		.then(
				// 			function () {
				// 				if($scope.createInvoiceModal){
				// 					$scope.createInvoiceModal.hide();
				// 				}
				// 			},
				// 			function (error) {
				// 				console.error(error);
				// 			}
				// 		);

				// }else{
					if($scope.createInvoiceModal){
						$scope.createInvoiceModal.hide();
					}
				// }
				// savePatientEmail($scope.recipient_email.value);
            },function(error){
                console.error(error);
				showErrorDialog();
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
		}

		$scope.deleteItem = function(index) {
			$scope.addedItems.splice(index, 1)
		}

		$scope.accounts = [];
		$scope.setAccounts = function (accounts) {
			$scope.accounts = accounts;
		}
		$scope.selectedOrg = {value: null};
		$scope.resolveConflicts = function (orgList) {
			$scope.orgList = orgList;
            $scope.selectedOrg.value = null;
            $scope.orgListModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.billing-resolve-org-conflict.tpl.html",
                title: "Resolve organisation conflict",
				show: true,
				backdrop: "static",
				keyboard: false,
			});
		}

		$scope.saveOrg = function() {
            if(!$scope.selectedOrg.value){
                return;
            }

            $scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.setXeroOrg($scope.selectedOrg.value)
				.then(
					function (resp) {
                        if($scope.orgListModal){
                            $scope.orgListModal.hide();
                        }
					},
					function (error) {
                        showErrorDialog();
						console.error(error);
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
        }


        $scope.viewInvoices = function (contactId) {
			$scope.addedItems = [];
			angular.element(document.querySelector("#headerTitle")).trigger("click");

			$mdDialog.show({
				controller: "invoiceEditController",
				templateUrl: "app/src/views/templates/view-edit-invoice.tmpl.html",
				parent: angular.element(document.body),
				clickOutsideToClose: false,
				scope: $scope.$new(true),
				preserveScope: false,
				onComplete: (scope, element) => {
					element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
					$("#modal-close-btn").click(function (event) {
						$mdDialog.hide();
					});
				},
				resolve: {
					accounts: () => $scope.accounts,
				},
			});
		}

		$scope.triggerSearch = function (){
			if($scope.contact_terms.value){
				fetchContact($scope.CP.patient.id,$scope.contact_terms.value, false);
			}
        }
        $scope.linkedContact = null;
		function fetchContact(patientId, term, showModal) {
            $scope.BillingAPI.isProcessing = true;
			$scope.linkedContact = null;
            $scope.BillingAPI.getContact(patientId,term).then(function(resp){
                $scope.patientContactList = resp.data;
				if(!term && $scope.patientContactList.length > 0){
					$scope.linkedContact = $scope.patientContactList[0];
				}
				if(showModal){
					displayContactsModal();
					if(!$scope.linkedContact){
						searchInAdvance();
						return;
					}
				}
				$scope.BillingAPI.isProcessing = false;
            },function(error){
                console.error(error);
				$scope.BillingAPI.isProcessing = false;
				showErrorDialog();
            });
        }

		$scope.registerPatient = function(){
			$scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.registerContact($scope.CP.patient.id).then(function(resp){
                if($scope.contactsModal){
					$scope.contactsModal.hide();
				}

            },function(error){
                console.error(error);
				showErrorDialog();
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
			
		}

		$scope.checkboxModel = {};
		$scope.selectedContract = null;

		$scope.watchCheckboxModel = function(index){
			const state = !$scope.checkboxModel[`${index}`];
			$scope.patientContactList.forEach((element,i) => {
				$scope.checkboxModel[`${i}`] = false;
			});
			$scope.checkboxModel[`${index}`] = state;
			if(state){
				$scope.selectedContract = $scope.patientContactList[index];
			}else{
				$scope.selectedContract = null;
			}
			
		
		}

		$scope.linkPatient = function() {
			$scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.linkContract($scope.CP.patient.id,$scope.selectedContract.contact_id).then(function(resp){
                if($scope.contactsModal){
					$scope.contactsModal.hide();
				}
            },function(error){
                console.error(error);
				showErrorDialog();
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
		}

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}	
		
		$scope.isPractitionerAvailable = function(){
			if(!$scope.CP.patient.practitioners || $scope.CP.patient.practitioners.length === 0){
				return false;
			}
			return true;
		}

		$scope.formatPractitioner = function () {
			let practitioner = $scope.CP.patient.practitioners[0].practitioner;
			if (!practitioner) {
				practitioner = "unknown";
			} else {
				practitioner = `${practitioner.name}`;
			}
			return practitioner;
		};

		$scope.openCalendar = function () {
			$mdDialog.show({
				controller: "calendarController",
				templateUrl: "app/src/views/templates/calendar.tmpl.html",
				// Appending dialog to document.body to cover sidenav in docs app
				// Modal dialogs should fully cover application to prevent interaction outside of dialog
				parent: angular.element(document.body),
				// targetEvent: ev,
				clickOutsideToClose: true,
				fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
				openFrom: "#anchor",
				closeTo: "#anchor",
				scope: $scope.$new(true),
				preserveScope: false,
				resolve: {
					patientId: () => $scope.CP.patient.id,
					patientName: () => $scope.CP.patient.firstName + " " + $scope.CP.patient.lastName,
					selectedAppointment: () => $scope.selectedAppointment,
				},
				onRemoving: () => {
					$scope.tooltipHide = false;
					fetchAppointmentsForPatient();
				},
			});
			
		};
		$scope.appointments = [];
		$scope.tooltipHide = false;

		function fetchAppointmentsForPatient() {
			$scope.isDataReady = false;
			$scope.appointments = [];
			$scope.AppointmentAPI.getAppointmentsForPatient($scope.CP.patient.id).then(
				function (response) {
					$scope.appointments = response.data;
					$scope.isDataReady = true;
				},
				function (error) {}
			);
		}

		$scope.triggerCalendarModal = function(appointment) {
			// $scope.popoverOpened = false;
			$scope.tooltipHide = true;
			$scope.selectedAppointment = appointment;
			$scope.openCalendar();
			// $scope.$apply();
		}

		$scope.showDymoPrinter = function (ev) {
			//dymoDiagnosisController
			$mdDialog.show({
				controller: "dymoDiagnosisController",
				templateUrl: "app/src/js/components/dymoDiagnosis/dymoDiagnosis.html",
				parent: angular.element(document.body),
				targetEvent: ev,
				clickOutsideToClose: false,
				fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
				openFrom: "#anchor",
				closeTo: "#anchor",
				scope: $scope.$new(true),
				preserveScope: false,
				resolve: {
					// patientId: () => $scope.CP.patient.id,
					// patientName: () => $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
				},
			});
		}
	
		
		// $scope.formatPractitioner = function() {
		// 	if(!$scope.CP.patient.practitioners || $scope.CP.patient.practitioners.length === 0){
		// 		return 'Practitioner unavailable';
		// 	}else {
		// 		let practitioner = $scope.CP.patient.practitioners[0].practitioner;
		// 		if(!practitioner){
		// 			practitioner = 'unknown';
		// 		}else{
		// 			practitioner = `${practitioner.name}`;
		// 		}
		// 		return `Practitioner: ${practitioner}, ${$scope.CP.patient.practitioners[0].organisation.name}`
		// 	}
		// }


	},
]);
