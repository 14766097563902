app.controller("welcomeController", [
	"$scope",
	"$timeout",
	"$state",
	"$modal",
	"UserService",
	"Session",
	"ContactAPI",
	"CurrentPatient",
	"AppointmentAPI",
	"PracticeService",
	"uiCalendarConfig",
	"PatientAPI",
	"CALENDAR_SEEN",
	'$filter',
	"ShareData",
	'CALENDAR_CNA_DNA',
	function ($scope, $timeout, $state, $modal, UserService, Session, ContactAPI, CurrentPatient,AppointmentAPI,PracticeService,uiCalendarConfig,PatientAPI,CALENDAR_SEEN,$filter,ShareData,CALENDAR_CNA_DNA) {
		$scope.US = UserService;
		$scope.CP = CurrentPatient;
		$scope.Session = Session;
		$scope.ContactAPI = ContactAPI;
		$scope.setPassword = {};
		$scope.setPassword.password = "";
		$scope.setPassword.passwordVerify = "";
		$scope.samePasswordError = false;
		$scope.terms = false;
		$scope.requestPwResetForm = {};
		$scope.isAdminRole = Session.user.roles.includes("ADMIN");
		$scope.showPassword = false;
		$scope.showPassword2 = false;
		$scope.ShareData = ShareData;
		$scope.appointment_categories = [];
		

		const draftIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem;margin-top:-0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"/> <path d="M12 6V18" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';
		const pendingIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem;margin-top:-0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ffa500" stroke-width="1.5"/> <path d="M12 6V18" stroke="#ffa500" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#ffa500" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';
		const paidIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem;margin-top:-0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#008000" stroke-width="1.5"/> <path d="M12 6V18" stroke="#008000" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#008000" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';

		function routeEvent(category) {
			const appointment_categories = $scope.appointment_categories ? $scope.appointment_categories : [];
			const obj = appointment_categories.find((t) => t.category_id === category);
			return obj ? { backgroundColor: `#${obj.colour}`, borderColor: `#${obj.colour}` } : {};
		}

		$scope.dateTimeHandler = function (strDate) {
			/**
			const offset = moment(strDate).utcOffset() / 60;
			if (strDate instanceof Date) {
				return strDate.toISOString().replace("Z", `+${offset}:00`);
			}

			return strDate.replace("Z", `+${offset}:00`); */
			// return moment(strDate).utc();
			return new Date(strDate);
		};

		$scope.formatDateTime = function (strDate) {
			const local = moment.utc(strDate).toDate();
			return new Date(moment(local).local());
			// return new Date(moment(strDate).utc().toISOString().replace('.000Z', ''));
		}

		this.$onInit = function () {
			$scope.CP.clearPatient();
			$scope.API = AppointmentAPI;
			$scope.PA = PatientAPI;
			$scope.eventSources = [];
			$scope.PracticeAPI = PracticeService;
			$scope.CALENDAR_ENABLE = false;
			$scope.CALENDAR_SEEN = CALENDAR_SEEN;
			$scope.CALENDAR_CNA_DNA = CALENDAR_CNA_DNA;
			$scope.currentSurgeon = {selected: null};
			$scope.selectedDate = {date: $filter("date")(new Date(), "EEE dd/MM/yyyy") }; // $filter("date")(new Date(), "dd-MMMM-yyyy");
		};

		function fetchDoctors () {
			if(Session.practice.practiceFeatures && Session.practice.practiceFeatures.calendarFeature === true){
				$scope.CALENDAR_ENABLE = true;
			}else{
				$scope.CALENDAR_ENABLE = false;
			}
			if(!$scope.CALENDAR_ENABLE){
				return;
			}
			$scope.API.getAppointmentUsers().then(
				function (resp) {
					$scope.ophthalmologists = [];
					resp.data.forEach(function (doc) {
						$scope.ophthalmologists.push({ name: doc.firstName + " " + doc.lastName, value: doc.id });
					});
					$scope.ophthalmologists.sort(function (a, b) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});

					const id = $scope.ShareData.clinicianIdHome ? $scope.ShareData.clinicianIdHome : Session.userId;
					const target = $scope.ophthalmologists.find((t) => t.value == id);
					if(target){
						$scope.currentSurgeon.selected = target.value;
					}else{
						$scope.currentSurgeon.selected = $scope.ophthalmologists[0].value;
					}
					
				},
				function (err) {
					console.log(err);
				}
			).then(function () {
				fetchUserSettings();
				fetchSetting();
			});
		}

		function fetchUserSettings() {
			$scope.PracticeAPI.isProcessing = true;
			$scope.PracticeAPI.queryCalendarSetting()
				.then(
					function (resp) {
						$scope.appointment_categories = resp.data.appointment_categories;
					},
					function (error) {
						console.error(error);
					}
				)
				.then(function () {
					$scope.PracticeAPI.isProcessing = false;
					fetchPatientAppointments();
				});
		}

		function fetchPatientAppointments() {
			if (!$scope.calendar_range_start || !$scope.calendar_range_end) {
				return;
			}
			$scope.API.isProcessing = true;
			$scope.API.queryPatientAppointment(
				$scope.currentSurgeon.selected ? $scope.currentSurgeon.selected : Session.userId,
				$scope.calendar_range_start,
				$scope.calendar_range_end,
				Session.practice.id
			)
				.then(
					function (resp) {
						// $scope.patientOptions = removeDuplicatedObj(resp.data.map(t=>({patient_id:t.patient_id, patient_name: t.patient_name})));
						$scope.allAppointments = angular.copy(resp.data);
						renderAppointmentEvents($scope.allAppointments);
					},
					function (error) {
						if($scope.serverErrorModal){
							$scope.serverErrorModal.hide();
						}
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				)
				.then(function () {
					$scope.API.isProcessing = false;
					$scope.loaded = true;
				});
		}

		function getIcon(invoiceList) {
			if (!invoiceList || invoiceList.length === 0) {
				return null;
			}
			if (invoiceList.some((t) => t.status === "DRAFT")) {
				return draftIcon;
			} else if (invoiceList.some((t) => t.status === "SUBMITTED")) {
				return pendingIcon;
			} else if (invoiceList.every((t) => t.status === "PAID")) {
				return paidIcon;
			}
			return null;
		}

		function renderAppointmentEvents(data) {
			$scope.events = data.map((t) => ({
				title: t.patient_name,
				id: t.patient_appointment_id,
				icon : getIcon(t.invoice_list),
				appointment: { ...t },
				start: moment($scope.dateTimeHandler(t.start_date_utc)),
				end: moment($scope.dateTimeHandler(t.start_date_utc)).add(t.duration_minutes, "m"),
				...routeEvent(t.category_id),
			}));
			$scope.eventSources = [$scope.events];
			if (uiCalendarConfig.calendars.homeCalendar) {
				uiCalendarConfig.calendars.homeCalendar.fullCalendar("removeEvents");
				uiCalendarConfig.calendars.homeCalendar.fullCalendar("addEventSource", $scope.events);
				uiCalendarConfig.calendars.homeCalendar.fullCalendar("rerenderEvents");
			}
		}

		$scope.getProgressTimestamp = function (key) {
			if($scope.appointment.patient_seen && $scope.appointment.patient_seen[key]){
				return '(' +$filter("date")(new Date($scope.appointment.patient_seen[`${key}_timestamp`]), "dd/MMM HH:mm") + ')';
			}
			return '';
		}

		function getProgressString(seen) {
			if (!seen) {
				return "";
			} 

			let timestamp = null;
			let seen_latest = null;

			if(seen.doctor_started){
				timestamp = new Date(seen.doctor_started_timestamp);
				seen_latest = 'Doctor started';
			}

			if(seen.doctor_finished){
				if(!timestamp || moment(seen.doctor_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.doctor_finished_timestamp);
					seen_latest = 'Doctor finished';
				}
			}

			if(seen.technician_started) {
				if(!timestamp || moment(seen.technician_started_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_started_timestamp);
					seen_latest = 'Technician started';
				}
			}

			if(seen.technician_finished) {
				if(!timestamp || moment(seen.technician_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_finished_timestamp);
					seen_latest = 'Technician finished';
				}
			}

			if(seen.nurse_started) {
				if(!timestamp || moment(seen.nurse_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse started';
					timestamp = new Date(seen.nurse_started_timestamp);
				}
			}

			if(seen.nurse_finished) {
				if(!timestamp || moment(seen.nurse_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse finished';
					timestamp = new Date(seen.nurse_finished_timestamp);
				}
			}


			if(seen.optometrist_started) {
				if(!timestamp || moment(seen.optometrist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist started';
					timestamp = new Date(seen.optometrist_started_timestamp);
				}
			}

			if(seen.optometrist_finished) {
				if(!timestamp || moment(seen.optometrist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist finished';
					timestamp = new Date(seen.optometrist_finished_timestamp);
				}
			}

			if(seen.orthoptist_started) {
				if(!timestamp || moment(seen.orthoptist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist started';
					timestamp = new Date(seen.orthoptist_started_timestamp);
				}
			}

			if(seen.orthoptist_finished) {
				if(!timestamp || moment(seen.orthoptist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist finished';
					timestamp = new Date(seen.orthoptist_finished_timestamp);
				}
			}

			if(seen.arrived) {
				if(!timestamp || moment(seen.arrived_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Arrived';
					timestamp = new Date(seen.arrived_timestamp);
				}
			}

			if(seen.departed) {
				if(!timestamp || moment(seen.departed_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Departed';
					timestamp = new Date(seen.departed_timestamp);
				}
			}

			if(seen.could_not_attend) {
				if(!timestamp || moment(seen.could_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'CNA';
					timestamp = new Date(seen.could_not_attend_timestamp);
				}
			}

			if(seen.did_not_attend) {
				if(!timestamp || moment(seen.did_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'DNA';
					timestamp = new Date(seen.did_not_attend_timestamp);
				}
			}

			if(seen.rebooked) {
				if(!timestamp || moment(seen.rebooked_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Rebooked';
					timestamp = new Date(seen.rebooked_timestamp);
				}
			}

			if(seen_latest && timestamp){
				return `(${seen_latest}: ${$filter("date")(new Date(timestamp), "HH:mm")})`
			}
			return "";
		}

		function selectPatient(patient, appointment) {
			$scope.CP.setPatient(patient, appointment);
			if (!$scope.CP.patientIsMine) {
				//need to transfer
				$scope.patientDetailsModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
					show: true,
					title: "PATIENT DETAILS",
				});
			} else {
				$state.go("refer.search");
			}
		}

		$scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

		$scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
					$state.go("refer.search");
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
				});
		};

		$scope.forwardPatientDashboard = function (appointment) {
			$scope.PA.getPatient(appointment.patient_id).then(
				function (resp) {
					selectPatient(resp.data, appointment);
				},
				function (error) {
					if($scope.serverErrorModal){
						$scope.serverErrorModal.hide();
					}
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}
			);
		};

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		function getFileName() {
            var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
            return (
                "CatTrax_appointments_" +
                theTime +
                ".pdf"
            );
        }

		function downloadPdf(selectedDate){
			const events = $scope.events.filter((t) => {
				const local = moment.utc(t.appointment.start_date_utc).toDate();
				return moment(local).local().format('YYYY-MM-DD').startsWith(selectedDate);
			});
			const data = {
				clinician_id: Number($scope.currentSurgeon.selected), 
				appointment_list_items: events.map(t=>({patient_id: t.appointment.patient_id, appointment_id: t.appointment.patient_appointment_id})),
			};
			$scope.API.downloadAppointmentList(data).then(function (resp) {
				downloadFile(resp.data, getFileName());
			});
		}

		$scope.eventAfterAllRender = function (view) {
			view.el.find('.fc-widget-header').each(function() {
				const selectedDate = $(this).parent().attr('data-date');
				$(this).append('<span class="fc-list-heading-alt"><a href><img src="app/build/img/download.svg"></a> &nbsp;</span>').click(function (event){
					event.preventDefault();
					event.stopPropagation();
					downloadPdf(selectedDate);
				});
			  })
		}

		function renderInvoiceDetail(invoiceList) {
		  let result = '';
		  if(invoiceList){
			invoiceList.forEach(t=>{result+= `<p>$${t.total} - ${t.status}</p>`});
		  }
          return `<div>${result}</div>`;
		}

		function removePopup () {
			var popups = document.querySelectorAll(".popover");
			if (popups) {
				   for (var i = 0; i < popups.length; i++) {
					   var popup = popups[i];
					   var popupElement = angular.element(popup);
					   popupElement.scope().$parent.isOpen = false;
					   popupElement.remove();
				   }
			   }
		}

		$scope.eventMouseout = function(event, jsEvent, view) {
			removePopup();
		}

		/* Render Tooltip */
		$scope.eventRender = function (eventObj, $el, view) {
			if (!eventObj.appointment) {
				return;
			}
			
			if (eventObj) {
				let element = $el.find(".fc-list-item-title");
				// eventObj.title = 'hello world';
				if (element.length === 0) {
					element = $el.find(".fc-title");
				}
				
				const category = $scope.appointment_categories.find(t=>t.category_id === eventObj.appointment.category_id);
				element[0].innerHTML = `${eventObj.title} ${getProgressString(eventObj.appointment.patient_seen)} ${category ? ' ['+category.name+']' : ''}`;

				element.attr("style", "font-family:'Gordita Regular';text-decoration: underline;cursor: pointer; display:flex; padding-left:24px").click(function (event) {
					event.preventDefault();
					event.stopPropagation();
					$scope.forwardPatientDashboard(eventObj.appointment);
				});
				removePopup();
				if(eventObj.icon){          
					$el.find(".fc-list-item-title").prepend(eventObj.icon);
					$el.popover({
						title: eventObj.title,
						content: $(renderInvoiceDetail(eventObj.appointment.invoice_list)), // eventObj.appointment.note,
						trigger: 'hover',
						placement: 'top',
						container: 'body',
						html: true
					  });
				 }

				const timeElement = $el.find(".fc-list-item-time");
				if(timeElement){
					timeElement.attr("style","cursor: pointer");
				}
			}
		};

		$scope.closeDetailsModal = function(){
			if($scope.appointment_clone && $scope.appointment.patient_appointment_id){
				const event = $scope.events.find((t) => t.appointment.patient_appointment_id === $scope.appointment.patient_appointment_id);
				event.appointment = $scope.appointment_clone
			}
			$scope.detailsModal.hide();
		}

		/* alert on eventClick */
		$scope.alertOnEventClick = function (data, jsEvent, view) {
			const event = $scope.events.find((t) => t.id === data.id);
			$scope.appointment = event.appointment;
			const start_date = new Date(event.start);
			$scope.appointment._startTime = new Date(1970, 0, 1, start_date.getHours(), start_date.getMinutes(), 0);
			$scope.appointment_clone = angular.copy(event.appointment);
			fetchAppointmentAudit($scope.appointment.patient_appointment_id);
			$scope.practiceName = $scope.Session.practice.name;
			$scope.detailsModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.appointment-detail.tpl.html",
				show: true,
				backdrop: "static",
				title: "Schedule Appointment",
			});
			return false;
			// if (data.url) {
			// 	window.open(data.url, "_blank");
			// 	return false;
			// }
		};

		$scope.deleteAppointment = function (obj) {
			$scope.API.isProcessing = true;

			$scope.API.deleteAppointment(obj.patient_id, obj.patient_appointment_id)
				.then(
					function (resp) {
						fetchPatientAppointments();
					},
					function (error) {}
				)
				.then(function () {
					$scope.API.isProcessing = false;
					if ($scope.detailsModal) {
						$scope.detailsModal.hide();
					}
				});
		};

		$scope.saveAppointment = function (valid) {
			if (valid) {
				$scope.API.isProcessing = true;
				if($scope.appointment._startTime){
					const original = new Date($scope.appointment.start_date_utc);
					original.setHours($scope.appointment._startTime.getHours());
					original.setMinutes($scope.appointment._startTime.getMinutes());
					$scope.appointment.start_date_utc = original.toISOString();
				}
				if ($scope.appointment.patient_appointment_id) {
					$scope.API.updateAppointment(
						$scope.appointment.patient_id,
						$scope.appointment.patient_appointment_id,
						$scope.appointment
					)
						.then(
							function (resp) {
								fetchPatientAppointments();
							},
							function (error) {}
						)
						.then(function () {
							$scope.API.isProcessing = false;
							if ($scope.detailsModal) {
								$scope.detailsModal.hide();
							}
						});
					return;
				}

				$scope.API.bookAppointment($scope.appointment)
					.then(
						function (resp) {
							fetchPatientAppointments();
						},
						function (error) {}
					)
					.then(function () {
						$scope.API.isProcessing = false;
						if ($scope.detailsModal) {
							$scope.detailsModal.hide();
						}
					});
			} else {
				console.log("invalid form");
			}
		};

		$scope.onPatientSeenChange = function (item) {
			if($scope.appointment.patient_seen[item]){
				$scope.appointment.patient_seen[`${item}_timestamp`] = new Date().toISOString(); // $filter("date")(new Date(), "yyyy-MM-dd HH:mm:ss.sss");
			}else{
				$scope.appointment.patient_seen[`${item}_timestamp`] = null;
			}
            if(!$scope.appointment.patient_seen.could_not_attend && !$scope.appointment.patient_seen.did_not_attend){
				$scope.appointment.patient_seen.rebooked_timestamp = null;
				$scope.appointment.patient_seen.rebooked = false;
			}
		}

		$scope.onCategoryChange = function (){
			if(!$scope.appointment.category_id){
				return;
			}
			const obj = $scope.appointment_categories.find(t=>t.category_id === $scope.appointment.category_id);
			if(obj) {
				$scope.appointment.duration_minutes = obj.duration;
			}
		}

		$scope.onSurgeonChange = function () {
			if ($scope.currentSurgeon.selected) {
				// console.log(`====${JSON.stringify($scope.currentSurgeon)}`);
				fetchPatientAppointments();
				$scope.ShareData.setHomeclinicianId($scope.currentSurgeon.selected);
				// return renderAppointmentEvents($scope.allAppointments.filter((t) => t.clinician_id === $scope.currentSurgeon));
				// uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate',moment("2024-12-04", "YYYY-MM-DD"));

			}
		};

		$scope.onDateChange = function () {
			uiCalendarConfig.calendars.homeCalendar.fullCalendar('gotoDate',moment($scope.selectedDate.date, 'DD/MM/YYYY'));
		}

		$scope.prevDate = function (){
			$scope.selectedDate.date = $filter("date")(moment($scope.selectedDate.date, "DD/MM/YYYY").add(-1, 'days').toDate(), "EEE dd/MM/yyyy");
			$scope.onDateChange();
		}

		$scope.nextDate = function (){
			$scope.selectedDate.date = $filter("date")(moment($scope.selectedDate.date, "DD/MM/YYYY").add(1, 'days').toDate(), "EEE dd/MM/yyyy");
			$scope.onDateChange();
		}

		function transformDate(value) {
            //E.g: 2024-11-19T03:32:20.482Z
            const array = value.toISOString().split('T');
            return array[0];
        }

        function viewRender(view, element) {
			$scope.calendar_range_start = moment(transformDate(view.start)).utc().toISOString();
			$scope.calendar_range_end = moment(transformDate(view.end)).utc().toISOString();
			$scope.selectedDate.date = $filter("date")(new Date(view.start), "EEE dd/MM/yyyy");
			fetchPatientAppointments();
		}

		$scope.uiConfig = {
			calendar: {
				editable: false,
				nowIndicator: true,
				selectable: false,
				selectHelper: true,
				header: {
					left: "title",
					center: "",
					right: "",
				},
				titleFormat: ' ',
				defaultView: "listDay",
				listDayAltFormat: "MMMM DD YYYY",
				eventClick: $scope.alertOnEventClick,
				eventMouseout: $scope.eventMouseout,
				eventRender: $scope.eventRender,
				eventAfterAllRender : $scope.eventAfterAllRender,
				businessHours: {
					dow: [1, 2, 3, 4, 5], // Monday - Thursday
					start: "8:00", // a start time (10am in this example)
					end: "18:00", // an end time (6pm in this example)
				},
				slotDuration: "00:10:00",
				minTime: "07:00:00",
				maxTime: "19:00:00",
				allDaySlot: false,
				// eventLimit: true,
				eventLimitText: "More",
				eventLimit: 3,
				viewRender: viewRender,
			},
		};

		/////////////////////////
		// Remove
		/////////////////////////
		// $timeout(function () {
		// 	$state.go("pre-op");
		// }, 200);
		// $timeout(function () {
		// 	// When testing with a user that has more than one practice you must
		// 	// manually select the practice before continuing
		// 	$scope.getStarted();
		// }, 500);
		/////////////////////////
		// Remove
		/////////////////////////

		$scope.toggleShowPassword = function () {
			$scope.showPassword = !$scope.showPassword;
		};

		$scope.toggleShowPassword2 = function () {
			$scope.showPassword2 = !$scope.showPassword2;
		};

		$scope.$watch(
			"Session",
			function (newValue, oldValue) {
				if (Session.user.firstName !== undefined) {
					$scope.firstName = Session.user.firstName;
				}
			},
			true
		);

		$scope.$watch(
			"selectedPractice",
			function (newValue, oldValue) {
				if (newValue) {
					// Session.setPractice(newValue);
					fetchDoctors();
				}
			},
			true
		);

		UserService.getUserPractices().then(function (resp) {
			if (Session.practiceOptions.length === 0) {
				Session.setPracticeOptions(resp.data);
			}
			$scope.practices = Session.practiceOptions;
			if (Session.practiceOptions.length === 1) {
				Session.setPractice(Session.practiceOptions[0]);
				$scope.US.getUser().then(function (resp) {
					Session.setCurrentUser(resp.data);
				}, function(error){
					console.log(error);
				})
				fetchDoctors();
			}
		});

		$scope.getStarted = function () {
			// Contact trace the user once they have clicked the "Get started button."
			$scope.CP.clearPatientResult();
			$scope.ContactAPI.isProcessing = true;
			$scope.ContactAPI.traceUser(Session.userId, Session.practice.id).then(
				function (resp) {
					$scope.ContactAPI.isProcessing = false;
					// On success go to search page
					$state.go("refer.search");
				},
				function (err) {
					$scope.ContactAPI.isProcessing = false;
					console.log(err);
				}
			);
		};

		$scope.goAdminPractice = function () {
			$state.go("admin-console", {
				type: "practice",
			});
		};

		$scope.goAdminUser = function () {
			$state.go("admin-console", {
				type: "user",
			});
		};
		$scope.appointmentAuditList = [];

		$scope.utc2local = function(utc) {
			return $filter("date")(moment.utc(utc).toDate(), "dd/MM/yyyy HH:mm");
		}

		function fetchAppointmentAudit(appointmentId) {
			$scope.appointmentAuditList = [];
			$scope.API.getReminderAudit(appointmentId).then(function(response){
				$scope.appointmentAuditList = response.data;
				$scope.appointmentAuditList.sort((a, b) => a.reminder_sent_time_UTC - b.reminder_sent_time_UTC);
			})
		}

		$scope.sendManualReminder = function (patient_appointment_id,patient_id){
			$scope.API.isProcessing = true;
			$scope.API.sendReminder(patient_appointment_id, patient_id)
				.then(
					function () {
						fetchAppointmentAudit(patient_appointment_id);
					},
					function () {
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				)
				.then(function () {
					$scope.API.isProcessing = false;
				});
		}

		$scope.getNofications = function () {
			UserService.getNotifications().then(function (resp) {
				// Check notification service to display any popups here
				// If more than 2 notifications are need a better way of handling the promises is needed
				// AngularStrap Modal is not passing the values in the scope to allow the promise to return correctly
				// Example response object: e.g. resp.data = [ {id: 1}, {id: 2}, {id: 3}, {id: 4} ];
				// id:1 was used for rev1 (expired - removed from code base)
				// id:2 was WDHB info (expired - removed from code base)
				// id:3 is being used for contact tracing
				for (let index = 0; index < resp.data.length; index++) {
					if (resp.data[index].id === 3) {
						$scope.contactTracingModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/notifications/modal.contact-tracing.tpl.html",
							show: true,
							backdrop: "static",
							keyboard: false,
						});
					} else if (resp.data[index].id === 4) {
						$scope.newFeatureModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/notifications/modal.new-feature.tpl.html",
							show: true,
							backdrop: "static",
							keyboard: false,
						});
					} else if (resp.data[index].id === 5) {
						$scope.privacyModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/notifications/modal.privacy.tpl.html",
							show: true,
							backdrop: "static",
							keyboard: false,
						});
					} else if (resp.data[index].id === 6) {
						$scope.newUpdateModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/notifications/modal.catrrax-update.tpl.html",
							show: true,
							backdrop: "static",
							keyboard: false,
						});
					} else if (resp.data[index].id === 8) {
						$scope.newUpdateModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/notifications/modal.nhi-outage.tpl.html",
							show: true,
							backdrop: "static",
							keyboard: false,
						});
					}
				}
			});
		};

		$scope.getNofications();

		$scope.getAppointmentType = function () {
			if ($scope.settingOptions) {
				const target = $scope.settingOptions.find((t) => t.appointment_category_id === $scope.appointment.category_id);
				if (target && (target.email_reminders_enabled || target.sms_reminders_enabled)) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		};

		$scope.isManualReminderDisabled = function () {
			let isCategoryEnabled = false;
			let isLastReminderEnabled = false;
			//check category category_id
			if ($scope.settingOptions) {
				const target = $scope.settingOptions.find((t) => t.appointment_category_id === $scope.appointment.category_id);
				if (target && (target.email_reminders_enabled || target.sms_reminders_enabled)) {
					isCategoryEnabled = true;
				}
			}
		    
			const latest = $scope.appointmentAuditList[$scope.appointmentAuditList.length - 1];
			if (!latest && !isCategoryEnabled) {
				return true;
			}

			if(latest && latest.reminder_sent_time_UTC){
				const differ = new Date() - moment.utc(latest.reminder_sent_time_UTC).toDate();
				if (differ > 1000 * 60 * 10) {
					isLastReminderEnabled = true;
				}
			}else{
				isLastReminderEnabled = true;
			}
			
			return !isCategoryEnabled || !isLastReminderEnabled;
		};

		$scope.isManualReminderShow = function (){
			const practiceFeatures = Session.practice.practiceFeatures;
			if($scope.appointment.patient_appointment_id && practiceFeatures && (practiceFeatures.appointmentEmailReminderFeature || practiceFeatures.appointmentSMSReminderFeature)){
				return true;
			}
			return false;
		}

		$scope.settingOptions = [];
		function fetchSetting() {
			$scope.settingOptions = [];
			$scope.API.isProcessing = true;
			$scope.API.getAppointmentReminderSetting($scope.Session.user.id)
				.then(
					function (response) {
						if(response.data && response.data.category_reminder_settings){
							$scope.settingOptions = response.data.category_reminder_settings;
						}
					},
					function (error) {
						console.log(error);
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		}
	},
]);