app.service("SurgeryAPI", [
	"$http",
	"ENV",
	"Session",
	"PreOp",
	function ($http, ENV, Session, PreOp) {
		var service = {
			isProcessing: false,

			getWorkUp: function (patientId, callback) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/surgery-workup/" + patientId,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
						"Content-Type": "application/json",
					},
				})
					.then(function (response) {
						if(!response.data.vision_section){
							response.data.vision_section = {vision:{
								od:{uncorrected_va_letter: 0, best_va_letter: 0, auto_refraction: 'true'},
								os:{uncorrected_va_letter: 0, best_va_letter: 0, auto_refraction: 'true'}
							}}
						}else if(response.data.vision_section){
							if(response.data.vision_section.vision && response.data.vision_section.vision.od && response.data.vision_section.vision.od.intraocular_pressure_iop !== null && response.data.vision_section.vision.od.intraocular_pressure_device === null){
								response.data.vision_section.vision.od.intraocular_pressure_iop = null;
							}
							if(response.data.vision_section.vision && response.data.vision_section.vision.os && response.data.vision_section.vision.os.intraocular_pressure_iop !== null && response.data.vision_section.vision.os.intraocular_pressure_device === null){
								response.data.vision_section.vision.os.intraocular_pressure_iop = null;
							}
							if(callback){
								callback();
							}
						}
						PreOp.setData(response.data);
					})
					.catch(function (err) {
						console.log(err);
					});
			},
			getWorkUpDetails: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/surgery-workup/" + patientId + "?isSettings=true",
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
						"Content-Type": "application/json",
					},
				})
					.then(function (response) {
						if(!response.data.vision_section){
							response.data.vision_section = {vision:{
								od:{uncorrected_va_letter: 0, best_va_letter: 0, auto_refraction: 'true'},
								os:{uncorrected_va_letter: 0, best_va_letter: 0, auto_refraction: 'true'}
							}}
						}else if(response.data.vision_section){
							if(response.data.vision_section.vision && response.data.vision_section.vision.od && response.data.vision_section.vision.od.intraocular_pressure_iop !== null && response.data.vision_section.vision.od.intraocular_pressure_device === null){
								response.data.vision_section.vision.od.intraocular_pressure_iop = null;
							}
							if(response.data.vision_section.vision && response.data.vision_section.vision.os && response.data.vision_section.vision.os.intraocular_pressure_iop !== null && response.data.vision_section.vision.os.intraocular_pressure_device === null){
								response.data.vision_section.vision.os.intraocular_pressure_iop = null;
							}
						}
						PreOp.setData(response.data);
					})
					.catch(function (err) {
						console.log(err);
					});
			},

			saveVision: function (patient_id, workup_id, data) {
				return $http({
					method: "PATCH",
					url: ENV.API + "/api/surgery-workup/vision",
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`
					},
					data: {
						workup_id: workup_id,
						patient_id: patient_id,
						vision_section: data,
						practice_id: Session.practice.id,
					},
				});
				// .catch(function (err) {
				// 	console.log(err);
				// });
			},

			saveHistory: function (patient_id, workup_id, data) {
				return $http({
					method: "PATCH",
					url: ENV.API + "/api/surgery-workup/ophthalmic",
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`
					},
					data: {
						workup_id: workup_id,
						patient_id: patient_id,
						ophthalmic_section: data,
						practice_id: Session.practice.id,
					},
				});
				// .catch(function () {
				// 	console.log(err);
				// });
			},

			savePlan: function (patient_id, workup_id, data) {
				return $http({
					method: "PATCH",
					url: ENV.API + "/api/surgery-workup/surgical_plan",
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`
					},
					data: {
						workup_id: workup_id,
						patient_id: patient_id,
						surgical_plan_section: data,
						practice_id: Session.practice.id,
					},
				});
				// .catch(function (err) {
				// 	console.log(err);
				// });
			},

			submitWorkUp: function (patient_id, workup_id) {
				return $http({
					method: "POST",
					url: ENV.API + "/api/surgery-workup/",
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`
					},
					responseType: "arraybuffer",
					data: {
						workup_id: workup_id,
						patient_id: patient_id,
						practice_id: Session.practice.id,
					},
				});
			},
			fetchBiometryFiles: function (patient_id) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/biometry/${patient_id}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`
					},
				});
			},
			deleteWorkup: function(patientId,documentId) {
				return $http({
					method: "DELETE",
					url: ENV.API + `/api/surgery-workup/${documentId}/patient/${patientId}/archive`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				})
			},
			restoreWorkup: function(patientId,documentId) {
				return $http({
					method: "PUT",
					url: ENV.API + `/api/surgery-workup/${documentId}/patient/${patientId}/archive`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				})
			}

		};

		return service;
	},
]);
