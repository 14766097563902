app.component("medicationTile", {
    templateUrl: "app/src/js/components/medications/MedicationTile.html",
    controller: "medicationTileController",
    bindings: {
        patientId: "=",
        onPrescriptionUpdate: "<",
        registration: "&",
    },
});

app.controller("medicationTileController", [
    "$scope",
    "$modal",
    "$filter",
    "MedicationAPI",
    "CurrentPatient",
    "Session",
    function ($scope, $modal, $filter, MedicationAPI, CurrentPatient, Session) {
        this.$onInit = function() {
			this.registration({
			  handler: this.triggerUpdate
			});
		  };

		this.triggerUpdate = function(){
			fetchPatientsMedication();
		}
        $scope.MA = MedicationAPI;
        $scope.popoverOpened1 = false;
        $scope.CP = CurrentPatient;
        $scope.patientMedication = [];
        $scope.positiveClick = undefined;
        $scope.isNormalPrescription = { value: true };

        $scope.formData = {
            pharmacy: "",
            search_term: "",
            pharmacy_object: null,
        };

        $scope.userIsPracticeAdmin = Session.user.roles.length === 1 && Session.user.roles[0] === "PRACTICE_ADMIN";
        function fetchPatientsMedication() {
            $scope.MA.isProcessing = true;
            $scope.MA.getPatientsMedication($scope.$ctrl.patientId).then(function (resp) {
                $scope.patientMedication = resp.data;
                checkNonPrescribeMedication($scope.patientMedication);
                $scope.patientMedicationCopy = angular.copy($scope.patientMedication);
                const array = $scope.filterRegular(resp.data);
                // const rows = [];
                const filteredData = [];
                const od_array_simple = array.filter(t => t.route === 'OD' || t.route === 'BOTH');
                const os_array_simple = array.filter(t => t.route === 'OS' || t.route === 'BOTH');

                const rows_count_simple = Math.max(od_array_simple.length, os_array_simple.length);
                for (let i = 0; i < rows_count_simple; i++) {
                    filteredData.push({ od: od_array_simple[i], os: os_array_simple[i] });
                }
                // $scope.diagnosisData_ocular = rows;
                $scope.filteredData = filteredData;
            },
                function (error) {
                    console.error(error);
                }).then(function () {
                    $scope.MA.isProcessing = false;
                })
        }

        $scope.$watch(
            "$ctrl.patientId",
            function (newValue, oldValue) {
                if (newValue) {
                    fetchPatientsMedication();
                }
            },
            true
        );

        function checkNonPrescribeMedication (medications){
            if (medications.find(t => t.electronic_prescription_available !== null && !t.electronic_prescription_available)) {
                $scope.isEDisabled = true;
                $scope.isNormalPrescription.value = true;
            } else {
                $scope.isEDisabled = false;
            }

        }

        $scope.$watch("patientMedication", function (newValue, oldValue) {
			if (newValue) {
				checkNonPrescribeMedication(newValue);
			}
		}, true);

        $scope.onMedicationUpdate = function () {
            fetchPatientsMedication();
        }

        function getFileName() {
            var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
            return (
                "CatTrax_prescription_" +
                theTime +
                "_" +
                $scope.CP.patient.firstName +
                "_" +
                $scope.CP.patient.lastName +
                "_" +
                $scope.CP.patient.dateOfBirth +
                ".pdf"
            );
        }

        $scope.isPrescriptionDisabled = function () {
            return $scope.patientMedication.length === 0;
        }

        $scope.addMedicationPrescription = function (isPrescription) {
            $scope.MA.isProcessing = true;
            const array = $scope.patientMedication.filter(t => t.medication_id == null);
            const data = array.map(t => ({ ...t, comments: t.comments[0].comment }));

            const post_op_array = $scope.patientMedication.filter(t => t.medication_type !== 'REGULAR' && t.medication_id).map(u => u.medication_id);

            const deleted = [];
            if($scope.patientMedicationCopy){
                $scope.patientMedicationCopy.forEach(element => {
                    if (!$scope.patientMedication.some(m => m.medication_id === element.medication_id)) {
                        deleted.push(element.medication_id);
                    }
                });
            }
          

            $scope.MA.addMedication($scope.$ctrl.patientId, data).then(function (resp) {
                if (isPrescription) {
                    const regularArray = resp.data;
                    const _data = $scope.patientMedication.filter(t => t.medication_id !== undefined).concat(regularArray);
                    prescription(_data);
                }
            },
                function (error) {
                    $scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
                    console.error(error);
                }).then(function () {
                    // $scope.MA.isProcessing = false;
                });


            // user case 1: Uncheck regular, click ’save’, this medication will disappear;
            // User case 3: Uncheck regular, click ’prescribe ’, this medication will disappear on dashboard, still on the pdf, not save in the next time
            post_op_array.forEach(element => {
                $scope.MA.deleteMedication($scope.$ctrl.patientId, element).then(function (resp) { }, function (error) { });
            });

            // User case 6: Uncheck regular, then click ‘X’ button, click ‘save’, delete the medication
            if(!isPrescription){
                deleted.forEach(element => {
                    if(element.medication_type !== 'REGULAR'){
                        $scope.MA.deleteMedication($scope.$ctrl.patientId, element).then(function (resp) { }, function (error) { });
                    }
                });
            }
         
            if (!isPrescription) {
                setTimeout(() => {
                    fetchPatientsMedication();
                    $scope.$ctrl.onPrescriptionUpdate();
                    if ($scope.medicationModal) {
                        $scope.medicationModal.hide();
                        $scope.medicationModal = null;
                    }
                }, 1000);
            }
        }

        function prescription(data) {
            $scope.MA.isProcessing = true;
            const array = data.map(t => {
                return {
                    dose: `${t.dose_quantity} ${t.dose_unit}`,
                    dose_quantity: t.dose_quantity,
                    dose_unit: t.dose_unit,
                    frequency: t.frequency,
                    route: t.route,
                    duration: t.duration,
                    comments: (t.comments && t.comments.length > 0) ? t.comments[0].comment : '',
                    snomed_medication_id: t.snomed_medication_id,
                    medication_nzulm_id: t.medication_nzulm_id,
                    medication_source_type: t.medication_source_type
                };
            });
            const regularList = data.filter(t => t.medication_type === "REGULAR").map(m => m.medication_id);

            if (regularList.length > 0) {
                $scope.MA.changeRegularMedication($scope.$ctrl.patientId, regularList);
            }

            if (!$scope.isNormalPrescription.value && $scope.formData.pharmacy_object) {
                // e-prescription//
                $scope.MA.electronicPrescription($scope.$ctrl.patientId, array, $scope.formData.pharmacy_object.pharmacyId).then(function (resp) {
                    $scope.MA.isProcessing = false;
                    // show e-prescription confirm modal
                    $scope.successObj = {
                        patientName: $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
                        pharmacy: $scope.formData.pharmacy
                    };
                    $scope.successModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.e-prescription.tpl.html",
                        show: true,
                        title: "Success",
                        onHide: function () {
                            fetchPatientsMedication();
                            $scope.$ctrl.onPrescriptionUpdate();
                            if ($scope.medicationModal) {
                                $scope.medicationModal.hide();
                                $scope.medicationModal = null;
                            }
                        },
                    });

                    
                },
                    function (error) {
                        $scope.MA.isProcessing = false;
                        console.error(error);
                        $scope.serverErrorModal = $modal({
                            scope: $scope,
                            templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                            show: true,
                            title: "CatTrax has encountered an error",
                        });
                    })
                return;
            }




            $scope.MA.prescription($scope.$ctrl.patientId, array).then(function (resp) {
                downloadFile(resp.data, getFileName());
                fetchPatientsMedication();
                $scope.$ctrl.onPrescriptionUpdate();
                if ($scope.medicationModal) {
                    $scope.medicationModal.hide();
                    $scope.medicationModal = null;
                }
            },
                function (error) {
                    console.error(error);
                    $scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
                }).then(function () {
                    $scope.MA.isProcessing = false;
                })
        }

        function downloadFile(data, filename, mime, bom) {
            // https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
            var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
            var blob = new Blob(blobData, { type: "application/pdf" });
            if (typeof window.navigator.msSaveBlob !== "undefined") {
                // IE workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
            } else {
                var blobURL = window.URL.createObjectURL(blob);
                var tempLink = document.createElement("a");
                tempLink.style.display = "none";
                tempLink.href = blobURL;
                tempLink.setAttribute("download", filename);

                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === "undefined") {
                    tempLink.setAttribute("target", "_blank");
                }

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);
                $scope.displaySpinner = false;
            }
        }

        $scope.closeMedicationModal = function () {
            $scope.positiveClick = function () {
                if ($scope.serverErrorModal) {
                    $scope.alertMessage = null;
                    $scope.serverErrorModal.hide();
                    $scope.serverErrorModal = null;
                }
                if ($scope.medicationModal) {
                    $scope.medicationModal.hide();
                    $scope.medicationModal = null;
                    fetchPatientsMedication();
                }
                $scope.positiveClick = undefined;
            }
            $scope.alertMessage = "If you quit now any new medications that have been added will not be saved and a prescription will not be generated.";
            $scope.serverErrorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                show: true,
                title: "The prescription has not yet been generated",
                backdrop: "static",
                keyboard: false,
            });

        }

        $scope.filterRegular = function (array) {
            return array.filter(t => t.medication_type === 'REGULAR');
        }

        function fetchPatientSetting () {
            $scope.MA.getPatientSettings($scope.CP.patient.id).then(
                function (resp) {
                    if (resp.data && resp.data.preferred_pharmacy) {
                        const obj = {...resp.data.preferred_pharmacy, pharmacyId: resp.data.preferred_pharmacy.pharmacy_id}
                        $scope.formData.pharmacy = obj.premises_name;
                        $scope.formData.pharmacy_object = obj;
                    }
                },
                function (err) {
                    console.log(`${JSON.stringify(err)}`)
                }
            );
        }

        $scope.showMedicationModal = function () {
            $scope.isNormalPrescription.value = true;
            $scope.pharmacy = {};
            $scope.formData = {
                pharmacy: "",
                search_term: "",
                pharmacy_object: null,
            };
            fetchPatientSetting();
            $scope.medicationModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.medication-form.tpl.html",
                show: true,
                title: "Medications",
                backdrop: "static",
                keyboard: false,
                onShow: function () {
                    const target = $scope.patientMedication.find(t => t.electronic_prescription_available !== null && !t.electronic_prescription_available);
                    if (target) { 
                        $scope.alertMessage = `${target.medication} is a Class C controlled drug and is not available on e-prescription. If you wish to prescribe codeine please print and sign your prescription`;
                        $scope.serverErrorModal = $modal({
                            scope: $scope,
                            templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                            show: true,
                            title: "",
                        });
        
                    }
                }
            });
        }

        $scope.fetch = function (value) {
            $scope.loading = true;
            return new Promise((resolve, reject) => {
                queryPharmacy(value, resolve, reject)
            })
        };

        function queryPharmacy(terms, resolve, reject) {
			$scope.result = [];
            $scope.noResults = false;
			$scope.MA.queryPharmacy(terms)
				.then(
					function (resp) {
						if (resp.data) {
							$scope.result=resp.data;
                            $scope.formData.search_term = terms;
							resolve($scope.result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
                        $scope.noResults = true;
                        $scope.result = [];
						reject(err)
					}
				).then(function () {
					$scope.loading = false;
				})
		}

        $scope.pharmacyOnSelect = function(item) {
			$scope.formData.pharmacy_object = item;
            // $scope.$ctrl.setProcedure({form:{
            //     diagnosis: $scope.formData.search_term,
            //     // operative_eye: $scope.formData.operative_eye,
            //     diagnosis_object: item
            // }});
        }
    },
]);