app.component("ethnicitySelector", {
	templateUrl: "app/src/js/components/ethnicitySelector/ethnicitySelector.html",
	controller: "ethnicitySelectorController",
	bindings: {
		data: "=",
	},
});
app.controller("ethnicitySelectorController", [
	"$scope",
	"UserService",
	function ($scope,UserService) {

		$scope.ethnicityOptions = [];
		$scope.ethnicityModel = [];
		$scope.dataSet = [];

		$scope.ethnicityOptionSettings = {
            displayProp: 'text',
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            },
            buttonClasses: "btn btn-dropdown-multiselect",
            showCheckAll: false,
            showUncheckAll: false,
            enableSearch: true,
            scrollableHeight: '20em',
	        scrollable: true,
			searchField: 'search_term',
        };

		this.$onInit = function () {
			$scope.US = UserService;
			fetchEthnicityOptions();
		}

		$scope.$watch(
			"$ctrl.data",
			function (newValue, oldValue) {
				if (!newValue) {
					$scope.ethnicityModel = [];
				}else {
					$scope.ethnicityModel = newValue.map(t=>({...t,id:t.code}));
				}
			},
			true
		);

		function orderOptions() {
			$scope.ethnicityOptions = [];
			$scope.ethnicityModel.forEach(element => {
				const target = $scope.dataSet.find(t=>t.id === element.id);
				if(target){
					$scope.ethnicityOptions.push(target)
				}
			});
			$scope.dataSet.forEach(t=>{
				if(!$scope.ethnicityOptions.find(m=>m.id === t.id)){
					$scope.ethnicityOptions.push(t)
				}
			})

		}

		function fetchEthnicityOptions() {
			$scope.US.getEthnicityCode().then(
				function (resp) {
					$scope.dataSet = resp.data.map(t=>({...t,id:t.code}));
					
					if($scope.$ctrl.data){
						$scope.ethnicityModel = $scope.$ctrl.data.map(t=>({...t,id:t.code}));
					}
					orderOptions();
				},
				function () {
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "Cannot request ethnicity codes",
					});
				}
			);
		}

		const onEthnicityUpdate = function () {
			$scope.$ctrl.data = $scope.ethnicityModel.map((t) => ({ ...t, code: t.id }));
			$scope._ethnicityModel = $scope.ethnicityModel.length > 0 ? JSON.stringify($scope.ethnicityModel) : undefined;
			$scope.ethnicityOptionRequired = $scope._ethnicityModel === undefined;
			orderOptions();
		};

        $scope.ethnicityOptionEvents = {
            onSelectionChanged: onEthnicityUpdate,
        }
		
	},
]);
